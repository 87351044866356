body {
	font-size: 1rem;
}

a {
	color: #005596;
	border-bottom: 2px solid rgba(51, 51, 51, 0.1);
	text-decoration: none;
}

a:hover {
	color: #f1af19;
	text-decoration: none;
	border-bottom: none;
}

.acrobat-reader-link:hover {
	border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}

.hero-img {
	width: 100%;
	height: 44vh;
	background-position: 50% 80%;
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: scroll;
}

.logo-img-wrapper {
	max-width: 250px;
	padding-left: 0rem;
	border-bottom: none;
}

.navbar {
	position: -webkit-sticky;
	position: sticky;
	left: 0%;
	top: 0%;
	right: 0%;
	border-bottom: 1px solid rgba(0, 0, 0, 0.08);
	background-color: #fff;
}

#navbar-flex-items {
	padding: 0.75rem 2.5rem;
}

.nav-menu {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.mobile-navigation-menu:not( .mm-menu_opened ) {
	display: none;
}

.login {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 200px;
	margin-top: 7px;
	padding: 0.75rem 2rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 15px 0px;
	background-color: #005596;
	box-shadow: none;
	-webkit-transition: all 800ms ease;
	transition: all 800ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 0.85rem;
	font-weight: 400;
	text-transform: none;
	height: 44px;
}

.login:hover {
	border-radius: 0px 15px;
	color: #fff;
	background-color: #f1af19;
}

.login.prescribe-now {
	margin-right: 1.5rem;
	background-color: #f1af19;
	color: #273374;
	font-weight: 600;
}

.login.prescribe-now:hover {
	background-color: #005596;
	color: #fff;
}

.login.prescribers-access-page {
	display: none;
}

.article-cb {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 375px;
	padding: 1.75rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: justify;
	justify-content: flex-end;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	border-top: 3px none #f1af19;
	border-radius: 0px 0px 20px;
	background-color: #2280c9;
}

.article-cb._2 {
	background-color: #0d70bd;
}

.article-cb._3 {
	background-color: #005596;
}

.article-cb._4 {
	background-color: #00457a;
}

@media screen and (max-width: 649px) {
	.article-cb {
		height: 300px;
	}
}

.h3-heading {
	margin-top: 0px;
	margin-bottom: 0.5rem;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 1.35rem;
	line-height: 1.65rem;
	font-weight: 300;
}

.h3-heading.blue {
	color: #273374;
	line-height: 2rem;
	font-weight: 400;
}

.button-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-left: 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.heading-wrapper {
	margin-top: 0px;
	margin-bottom: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}

.date-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0rem;
	margin-bottom: 1rem;
}

.article-date {
	margin-right: 1rem;
	margin-bottom: 0rem;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-style: italic;
	font-weight: 400;
}

.read-more-button {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 0px 2rem 0px 0px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-bottom: 1px none #f1af19;
	background-color: transparent;
	background-image: var(--white-arrow);
	background-position: 100% 50%;
	background-size: 15px;
	background-repeat: no-repeat;
	-webkit-transition: color 200ms ease;
	transition: color 200ms ease;
	font-family: 'Open Sans', sans-serif;
	font-size: 0.85rem;
	line-height: 1.25rem;
	font-style: italic;
}

.read-more-button:hover {
	border-bottom-color: #f1af19;
	background-image: var(--yellow-arrow);
	color: #f1ae1b;
}

.article-source-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-left: auto;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.content-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 3rem 5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	background-color: rgba(51, 51, 51, 0.05);
}

.content-wrapper.user-access {
	padding-top: 3rem;
	padding-bottom: 3rem;
	background-color: #fff;
}

.content-wrapper.testimonials {
	padding-top: 3rem;
	padding-bottom: 3rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
}

.content-wrapper.testimonials.login-page {
	padding-top: 1rem;
	padding-bottom: 0rem;
	background-color: transparent;
}

.content-wrapper.about {
	overflow: hidden;
	padding: 3rem 5rem;
	background-color: rgba(51, 51, 51, 0.05);
}

.heading-2 {
	margin-top: 0px;
	margin-bottom: 1rem;
	font-family: 'Open Sans', sans-serif;
	color: #273374;
	font-size: 2.25rem;
	line-height: 2.25rem;
	font-weight: 400;
	text-align: center;
}

.heading-2.meet-the-expert {
	text-align: left;
}

.article-source {
	display: flex;
	width: 100%;
	height: 35px;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	align-items: center;
	justify-content: center;
	background-color: #fff;
}

.article-cb-content-wrap .button-wrap .article-source img {
	max-height: 35px;
	width: auto !important;
}

.footer-section {
	padding: 3rem 5rem 0rem;
	border-top: 4px none #f1af19;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	background-color: #005596;
}

.footer-signoff-text {
	display: inline-block;
	margin-right: 1rem;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 0.75rem;
	font-weight: 400;
	text-align: left;
}

.back-to-top-link {
	display: inline-block;
	padding: 0.5rem 2.5rem 0.5rem 1.25rem;
	border: 2px solid #00435b;
	background-image: var(--arrow-medium-up-white);
	background-position: 90% 50%;
	background-size: 14px;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 1rem;
	font-weight: 400;
}

.back-to-top-link:hover {
	border-color: rgba(5, 90, 127, 0.25);
	background-image: var(--arrow-medium-up-white);
	background-position: 90% 31%;
	background-size: 14px;
	background-repeat: no-repeat;
	opacity: 1;
	color: #d8d8d8;
	text-decoration: none;
}

.grip-logo-text-box {
	color: #000;
	font-size: 0.5rem;
	line-height: 0.5rem;
	font-weight: 700;
	letter-spacing: 0.01rem;
	text-decoration: none;
	text-transform: uppercase;
}

.grip-logo-text-box.webdevby-white {
	color: #fff;
}

.acrobat-reader-link {
	display: inline-block;
	margin-right: 2rem;
	margin-bottom: 0rem;
	margin-left: 0rem;
	border-bottom: 1px solid rgba(255, 255, 255, 0.25);
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 0.75rem;
	line-height: 1rem;
	font-weight: 400;
	text-decoration: none;
}

.acrobat-reader-link:hover {
	color: #f1af19;
	text-decoration: none;
}

.footer-signoff {
	display: block;
	width: 100%;
	max-width: 1600px;
	margin-top: 0rem;
	margin-right: auto;
	margin-left: auto;
	padding-bottom: 1rem;
	justify-content: flex-start;
	border-top: 1px none hsla(0, 0%, 100%, 0.1);
	border-top-left-radius: 0px;
	background-color: transparent;
	font-size: 0.75rem;
	line-height: 1.25rem;
}

.back-to-top-div {
	display: none;
	margin-top: 1rem;
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
	border-top: 1px solid hsla(0, 0%, 100%, 0.25);
	border-bottom: 1px none grey;
	background-color: #ed762e;
	color: #fff;
	text-align: center;
}

.footer-heading {
	margin-top: 0px;
	margin-bottom: 0px;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-weight: 700;
}

.address-para {
	margin-bottom: 2rem;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 0.8rem;
	line-height: 1.25rem;
	font-weight: 300;
}

.login-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0.5rem;
	margin-bottom: 0rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 600px;
	height: auto;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.prescribers-login {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 250px;
	margin-bottom: 0rem;
	padding: 1.15rem 2rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 15px 0px;
	background-color: #f1ae1b;
	box-shadow: none;
	-webkit-transition: all 800ms ease;
	transition: all 800ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #273374;
	font-size: 1.2rem;
	font-weight: 400;
}

.prescribers-login:hover {
	border-radius: 0px 15px;
	background-color: #005596;
	background-image: none;
	color: #fff;
}

.patients-login {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 250px;
	padding: 1.15rem 2rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-radius: 15px 0px;
	background-color: #f1ae1b;
	box-shadow: none;
	-webkit-transition: all 850ms ease;
	transition: all 850ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #273374;
	font-size: 1.2rem;
	font-weight: 400;
}

.patients-login:hover {
	border-radius: 0px 15px;
	background-color: #005596;
	color: #fff;
}

.author-name,
.attribution {
	display: block;
	width: 100%;
	padding-bottom: 0rem;
	padding-left: 0rem;
	justify-content: center;
	align-items: center;
	align-self: flex-start;
	font-family: 'Open Sans', sans-serif;
	color: #005596;
	font-size: 0.9rem;
	line-height: 1rem;
	font-weight: 600;
	text-align: center;
	text-transform: none;
}

.left-arrow-wrapper {
	left: 0px;
	top: 0%;
	right: auto;
	bottom: 0%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 50px;
	margin-left: 0px;
	padding-left: 0.5rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.right-arrow-wrapper {
	left: auto;
	top: 0%;
	right: 0%;
	bottom: 0%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 60px;
	margin-right: 0px;
	margin-left: 0px;
	padding-right: 0.5rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.footer-left-content-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	padding-left: 0rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.footer-right-content-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 70%;
	padding-right: 0rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.cb-static {
	width: 33.33%;
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.cb-static.footer-cb-left {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	margin-right: 0rem;
	padding-top: 0rem;
	padding-right: 0rem;
	padding-bottom: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.social-media-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.footer-container {
	display: block;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
}

.footer-container.footer-content-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 1600px;
	padding-top: 1rem;
	padding-bottom: 1rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border-bottom: 1px none hsla(0, 0%, 97.5%, 0.15);
}

.youtube-social-icon {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 0.5rem;
	margin-bottom: 0rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top-left-radius: 5px;
	border-bottom-right-radius: 5px;
	background-color: #f1af19;
	-webkit-transition: all 600ms ease;
	transition: all 600ms ease;
}

.youtube-social-icon:hover {
	border-radius: 0px 5px;
	background-color: rgba(255, 255, 255, 0.08);
}

.social-media-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 2rem;
	height: 2rem;
	margin-right: 0rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
	background-image: var(--facebook-white-01);
	background-position: 50% 50%;
	background-size: 25px;
	background-repeat: no-repeat;
	text-align: center;
}

.social-media-link:hover {
	background-color: transparent;
	background-image: var(--facebook-white-01);
}

.social-media-link.youtube {
	background-image: var(--youtube-icon);
	background-size: 22px;
}

.social-media-link.youtube:hover {
	background-image: var(--youtube-icon);
	background-position: 50% 50%;
	background-size: 22px;
}

.facebook-social-icon {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 1rem;
	margin-bottom: 0rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top-left-radius: 5px;
	border-bottom-right-radius: 5px;
	background-color: #f1af19;
	-webkit-transition: all 600ms ease;
	transition: all 600ms ease;
}

.facebook-social-icon:hover {
	border-radius: 0px 5px;
	background-color: rgba(255, 255, 255, 0.08);
}

.footer-right-col {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	margin-right: 0px;
	margin-left: 0px;
	padding-bottom: 0.5rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.footer-signoff-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.footer-left-col {
	width: auto;
}

.footer-left-col h1 {
	line-height: 1rem;
	box-sizing: border-box;
	display: inline-block;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 0.7rem;
	font-weight: 400;
	margin-bottom: 0px;
}

.heading-and-subtitle-wrapper {
	display: flex;
	margin-right: 0rem;
	margin-left: 0rem;
	flex-direction: column;
	align-items: center;
}

.heading-and-subtitle-wrapper.access-form-container {
	width: 60%;
}

.heading-and-subtitle-wrapper.prescriber-login-page {
	margin-bottom: 0rem;
}

.sub-title-text {
	margin-bottom: 0.75rem;
	padding-right: 1rem;
	padding-left: 1rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-radius: 10px 0px;
	background-color: rgba(13, 112, 189, 0.15);
	font-family: 'Open Sans', sans-serif;
	color: #005596;
	font-size: 0.7rem;
	font-weight: 700;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.sub-title-text.slider {
	margin-right: 0rem;
	background-color: #fff;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(13, 112, 189, 0.4)), to(rgba(13, 112, 189, 0.4)));
	background-image: linear-gradient(180deg, rgba(13, 112, 189, 0.4), rgba(13, 112, 189, 0.4));
}

.icon-2 {
	display: none;
	color: #fff;
}

.slider-inner-content {
	display: inline-block;
	width: 100%;
	height: auto;
	padding: 0rem 1rem;
	border-radius: 0px;
	background-color: transparent;
}

.slider {
	overflow: hidden;
	height: auto;
	margin: 0rem 5.5rem 2rem 0rem;
}

.slider-navigation {
	top: auto;
	display: block;
	color: #f1ae1b;
	font-size: 0.5rem;
}

.left-arrow-icon {
	display: block;
}

.inner-content-wrapper {
	position: relative;
	margin-top: 0rem;
}

.testimonial-slide {
	overflow: hidden;
	width: auto;
	height: auto;
	margin-left: 0px;
	padding: 2rem 5rem;
	background-color: transparent;
}

.slide-mask {
	overflow: visible;
	width: 100%;
}

.arrow-slider {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 45px;
	height: 45px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-style: none;
	border-width: 2px;
	border-color: #fff;
	border-radius: 100px;
	background-color: #005596;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.arrow-slider:hover {
	background-color: #f1af19;
	-webkit-transform: scale(1.05);
	-ms-transform: scale(1.05);
	transform: scale(1.05);
}

.icon-3 {
	display: none;
	font-size: 0.75rem;
}

.slider-wrapper {
	overflow: hidden;
	width: 70vw;
	margin-left: auto;
}

.slider-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	height: auto;
	padding: 2rem 3rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 20px 0px;
	background-color: rgba(51, 51, 51, 0.05);
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
	cursor: pointer;
}

.slider-content:hover {
	border-radius: 20px 0px;
	background-color: rgba(51, 51, 51, 0.05);
}

.slider-content.inside-page {
	height: auto;
	padding-top: 2rem;
	padding-bottom: 2rem;
	background-color: rgba(51, 51, 51, 0.05);
	cursor: auto;
}

.slider-content.inside-page:hover {
	background-color: rgba(51, 51, 51, 0.05);
}

.testimonial-slider {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 0rem;
	padding-left: 0rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
	max-width: 100%;
}

.button-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 75px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top-left-radius: 0px;
	border-bottom-right-radius: 20px;
	background-color: #fff;
	border-radius: 20px 0px 0px;
}

.article-cb-content-wrap {
	width: 100%;
	padding: 0 0.675rem;
	border-top-left-radius: 20px;
	border-bottom-right-radius: 20px;
}

.article-cb-content-wrap.last {
	margin-right: 0rem;
}

.additional-content-wrapper .owl-carousel .owl-nav button {
	position: absolute;
	top: 45%;
	font-size: 0;
	line-height: 0;
	width: 35px;
	height: 35px;
	border-radius: 25px;
	border-color: #005596;
	border-radius: 100px;
	background-color: #005596;
	color: #fff;
	background-image: url("/assets/images/white-arrow-left.svg");
	background-position: 50% 50%;
	background-size: 17px;
	background-repeat: no-repeat;
	-webkit-transition: all 300ms ease, background-color 300ms ease;
	transition: all 300ms ease, background-color 300ms ease;
}

.additional-content-wrapper .owl-carousel .owl-nav button:hover {
	border-style: none;
	border-color: #005596;
	background-color: #f1ae1b;
	-webkit-transform: scale(1.05);
	-ms-transform: scale(1.05);
	transform: scale(1.05);
}

.additional-content-wrapper .owl-carousel .owl-nav button span {
	display: block;
	margin-top: -2px;
}

.additional-content-wrapper .owl-carousel .owl-nav button.owl-prev {
	left: -50px;
}

.additional-content-wrapper .owl-nav button.owl-next {
	right: -50px;
	background-image: url("/assets/images/white-arrow-right.svg");
}

.position-title {
	font-family: 'Open Sans', sans-serif;
	color: #333;
	font-size: 0.9rem;
	line-height: 1rem;
	text-align: center;
}

.footer-middle-col {
	display: block;
	width: auto;
	justify-content: center;
}

.intro-paragraph {
	margin-bottom: 1rem;
	font-family: 'Open Sans', sans-serif;
	color: #333;
	font-size: 1.25rem;
	line-height: 1.5rem;
	font-weight: 400;
}

.paragraph-text-2 {
	margin-bottom: 1rem;
	font-family: 'Open Sans', sans-serif;
	color: #333;
	font-size: 1rem;
	line-height: 1.5rem;
	text-decoration: none;
}

.paragraph-text-2.list-item {
	margin-bottom: 0rem;
}

.left-nav-list-content-wrap {
	display: block;
	overflow: hidden;
	height: auto;
	margin-top: 0rem;
	margin-right: 1.5rem;
	padding-top: 0.25rem;
	padding-bottom: 1rem;
	border-right: 1px none rgba(51, 51, 51, 0.1);
	border-top-left-radius: 30px;
	border-bottom-right-radius: 30px;
	background-color: rgba(51, 51, 51, 0.03);
}

.content-container {
	display: block;
	width: 50%;
	max-width: none;
	margin-right: 0px;
	margin-left: 0px;
	padding-top: 0rem;
	padding-right: 0rem;
	padding-left: 0rem;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	background-color: #fff;
}

.content-container.threequarterwidth {
	width: 100%;
	max-width: none;
	margin-right: 0rem;
	padding-right: 0rem;
	padding-left: 0rem;
}

.text-block-21 {
	font-family: 'Open Sans', sans-serif;
	color: #005596;
	font-size: 0.9rem;
	line-height: 1rem;
	font-weight: 400;
	text-transform: none;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
.h2-header-link:hover,
.h3-header-link:hover,
.h4-header-link:hover,
.h5-header-link:hover,
.h6-header-link:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #f1af19;
}

h1,
.heading-6 {
	margin-top: 0px;
	font-family: 'Open Sans', sans-serif;
	color: #273374;
	font-size: 2.5rem;
	font-weight: 400;
}

h1 a {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 1px solid rgba(51, 51, 51, 0.1);
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #005596;
	text-decoration: none;
}

h2,
h3,
h4,
.heading-18 {
	margin-top: 0px;
	margin-bottom: 5px;
	font-family: 'Open Sans', sans-serif;
	color: #273374;
	font-weight: 400;
}

h2 a,
.h2-header-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 1px solid rgba(51, 51, 51, 0.1);
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #005596;
	font-size: 2rem;
	line-height: 2.25rem;
	font-weight: 400;
	text-decoration: none;
}

h3 a,
.h3-header-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 1px solid rgba(51, 51, 51, 0.1);
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #005596;
	font-size: 24px;
	line-height: 30px;
	font-weight: 400;
	text-decoration: none;
}

h4 a,
.h4-header-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 1px solid rgba(51, 51, 51, 0.1);
	transition: all 200ms ease;
	color: #005596;
	font-size: 18px;
	line-height: 24px;
	font-weight: 400;
	text-decoration: none;
}

h5,
h6,
.heading-10 {
	margin-bottom: 5px;
	color: #273374;
	font-weight: 400;
	text-transform: uppercase;
}

h5 a,
.h5-header-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 1px solid rgba(51, 51, 51, 0.1);
	transition: all 200ms ease;
	color: #005596;
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
	text-decoration: none;
	text-transform: uppercase;
}

h6 a,
.h6-header-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 1px solid rgba(51, 51, 51, 0.1);
	transition: all 200ms ease;
	color: #005596;
	font-size: 12px;
	line-height: 18px;
	font-weight: 400;
	text-decoration: none;
	text-transform: uppercase;
}

.main-content-column {
	padding-right: 0px;
	padding-left: 0px;
}

.left-nav-nested-list-item {
	margin-bottom: 0px;
	border-bottom: 1px none rgba(51, 51, 51, 0.08);
}

.left-nav-nested-list-item.grandchild {
	border-bottom-style: none;
}

.left-nav-nested-list-item.grandchild.current {
	background-color: transparent;
}

.left-nav-nested-list-item.great-grandchild {
	border-bottom-style: none;
}

.left-nav-nested-list-item.great-grandchild.current {
	background-color: transparent;
}

.breadcrumb-nav-link {
	border-bottom: 0.5px solid rgba(51, 51, 51, 0.05);
	font-family: 'Open Sans', sans-serif;
	color: #333;
	font-size: 0.9rem;
	line-height: 1rem;
	font-weight: 400;
	text-decoration: none;
	text-transform: none;
}

.breadcrumb-nav-link:hover {
	border-bottom-style: solid;
	border-bottom-color: transparent;
	color: #0d70bd;
}

.breadcrumb-nav-link.w--current {
	color: #28313b;
	font-weight: 700;
}

.left-nav-list {
	margin-top: 0rem;
	margin-bottom: 0rem;
	padding-left: 0px;
	box-shadow: none;
}

hr {
	border-top: 1px rgba(51, 51, 51, 0.05);
}

.horizontal-divider {
	width: 100%;
	height: 1px;
	margin-top: 2rem !important;
	margin-bottom: 2rem !important;
	background-color: rgba(51, 51, 51, 0.05);
	color: #003c43;
}

.main-content-wrapper {
	display: block;
	width: 95%;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

.left-nav-nested-list-link {
	display: block;
	padding: 0.75em 1.5rem;
	border: 2px none transparent;
	font-family: Montserrat, sans-serif;
	color: #333;
	font-size: 0.85em;
	line-height: 1.375em;
	font-weight: 500;
	text-decoration: none;
	text-transform: capitalize;
}

.left-nav-nested-list-link:hover {
	border: 2px none #48bdd7;
	background-color: transparent;
	background-image: none;
	color: #0915f8;
}

.left-nav-nested-list-link.w--current {
	color: #5e8dbe;
}

.left-nav-nested-list-link.w--current:hover {
	color: #0915f8;
}

.left-nav-nested-list-link.grandchild {
	border-style: none;
	-webkit-transition: all 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: all 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	font-family: 'Open Sans', sans-serif;
	color: #273374;
	font-size: 0.8rem;
	line-height: 1.15rem;
	font-weight: 400;
}

.left-nav-nested-list-link.grandchild:hover {
	background-color: transparent;
	background-image: var(--blue-arrow-right);
	background-position: 5% 50%;
	background-size: 5px;
	background-repeat: no-repeat;
	-webkit-transform: translate(5px, 0px);
	-ms-transform: translate(5px, 0px);
	transform: translate(5px, 0px);
	color: #005596;
}

.left-nav-nested-list-link.grandchild.w--current {
	margin-left: 1rem;
	background-color: transparent;
	background-image: var(--blue-arrow-right);
	background-position: 5% 50%;
	background-size: 5px;
	background-repeat: no-repeat;
	font-family: 'Open Sans', sans-serif;
	color: #005596;
}

.left-nav-nested-list-link.grandchild.w--current:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	color: #005596;
}

.left-nav-nested-list-link.great-grandchild {
	padding-left: 2.5rem;
	font-family: 'Open Sans', sans-serif;
	color: #273374;
	font-size: 0.75rem;
	line-height: 1rem;
	font-weight: 400;
}

.left-nav-nested-list-link.great-grandchild:hover {
	color: #005596;
}

.left-nav-nested-list-link.great-grandchild.w--current {
	box-shadow: 9px 0 0 -16px #82bd0a;
}

.left-nav-nested-list-link.great-grandchild.current {
	margin-left: 2.25rem;
	padding-left: 1.75rem;
	background-color: transparent;
	background-image: var(--blue-arrow-right);
	background-position: 5% 50%;
	background-size: 5px;
	background-repeat: no-repeat;
	color: #005596;
	font-size: 0.75rem;
}

.left-nav-nested-list-link.great-grandchild.current.w--current {
	margin-right: 0rem;
	background-color: transparent;
	font-family: 'Open Sans', sans-serif;
	color: #005596;
}

.breadcrumb-divider {
	margin-left: 0.5rem;
	float: right;
}

.left-nav-list-item {
	margin-bottom: 0rem;
	border-bottom: 0.75px solid rgba(51, 51, 51, 0.1);
}

.left-nav-list-item:hover {
	background-color: transparent;
}

.left-nav-column {
	display: block;
	padding-right: 0px;
	padding-left: 0px;
}

.button-styling {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 550px;
	margin-top: 1rem;
	margin-bottom: 2rem;
	padding-left: 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-family: 'Open Sans', sans-serif;
	font-weight: 800;
}

.left-nav-list-link {
	display: block;
	padding: 0.75rem 1.5rem 0.75rem 2rem;
	border-bottom-style: none;
	background-image: var(--blue-arrow-right);
	background-position: 6% 50%;
	background-size: 5px;
	background-repeat: no-repeat;
	-webkit-transition: all 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: all 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	font-family: 'Open Sans', sans-serif;
	color: #273374;
	font-size: 0.9rem;
	font-weight: 400;
	text-decoration: none;
	text-transform: none;
}

.left-nav-list-link:hover {
	padding-left: 2.5rem;
	background-color: rgba(13, 112, 189, 0.05);
	background-image: var(--blue-arrow-right);
	background-position: 10% 50%;
	background-size: 5px;
	background-repeat: no-repeat;
	-webkit-transform: translate(5px, 0px);
	-ms-transform: translate(5px, 0px);
	transform: translate(5px, 0px);
	color: #005596;
	text-decoration: none;
}

.left-nav-list-link.w--current {
	padding-left: 2.5rem;
	border-bottom: 1px none rgba(51, 51, 51, 0.1);
	background-color: rgba(13, 112, 189, 0.05);
	background-image: var(--blue-arrow-right);
	background-position: 10% 50%;
	background-size: 5px;
	background-repeat: no-repeat;
	color: #005596;
}

blockquote,
.block-quote {
	margin-top: 10px;
	margin-bottom: 20px;
	border-left-color: #f1af19;
	background-color: rgba(241, 175, 25, 0.11);
	color: #333;
	font-size: 1.2rem;
	line-height: 1.75rem;
	font-weight: 400;
}

.ordered-list-2 {
	margin-bottom: 1rem;
	font-family: 'Open Sans', sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
}

.main-content-section {
	max-width: none;
	margin-right: 1.5rem;
	font-family: 'Open Sans', sans-serif;
}

.main-content-section.full-page {
	margin-right: 0rem;
}

.inside-page-bg-img {
	position: relative;
	height: 175px;
	margin-right: 0px;
	margin-left: 0px;
	padding-top: 0px;
	padding-right: 0px;
	padding-left: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	background-color: transparent;
	background-image: var(--banner-image);
	background-position: 50% 80%;
	background-size: cover;
	background-repeat: no-repeat;
}

.table-div {
	font-size: 0.8rem;
	font-weight: 400;
}

.breadcrumb-list {
	margin: 0rem 0.125rem;
	padding-left: 0px;
	font-size: 0.6875rem;
	line-height: 1em;
	text-transform: uppercase;
}

.inside-content-background {
	position: relative;
	display: block;
	max-width: 1400px;
	margin-top: 0px;
	margin-right: auto;
	margin-left: auto;
	padding: 3rem 0px;
	border-top: 1px none #000;
	background-color: #fff;
	min-height: 50vh;
}

.left-nav-nested-list-item-wrapper {
	margin-bottom: 0px;
	border-bottom: 1px none rgba(51, 51, 51, 0.08);
}

.inside-img-wrap {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 150px;
	margin-bottom: 1rem;
}

.section-wrap {
	background-color: rgba(51, 51, 51, 0.05);
}

.inside-page-breadcrumb-wrap {
	display: inline-block;
	width: 100%;
	margin-bottom: 2rem;
	padding-bottom: 0.5rem;
	border-bottom: 1px solid rgba(51, 51, 51, 0.05);
}

.breadcrumb-list-item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0.25rem;
	margin-right: 0.5rem;
	margin-bottom: 0.25rem;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.left-nav-nested-list {
	margin-top: 0em;
	margin-bottom: 0em;
	padding-top: 0em;
	padding-bottom: 0em;
	border-top: 2px none #498fa3;
}

.unordered-list {
	margin-bottom: 1rem;
}

.paragraph {
	margin-top: 0rem;
	margin-bottom: 1.5rem;
	font-family: 'Open Sans', sans-serif;
	color: #333;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 400;
}

.paragraph.centered {
	max-width: 650px;
	margin-top: 0rem;
	margin-bottom: 1.5rem;
	text-align: center;
}

.paragraph.centered.required-field {
	margin-bottom: 0.125rem;
}

.paragraph.private-info-text {
	font-size: 0.9rem;
	line-height: 1rem;
}

.paragraph.no-padding {
	margin-bottom: 0rem;
}

.paragraph.no-padding.prescribe-now {
	margin-top: 1rem;
	text-align: left;
}

.paragraph.testimonials {
	text-align: center;
}

.primary,
.js-submit-button.cms_form_button.primary,
.primary-button {
	margin-top: 0px;
	padding: 0.75rem 2rem !important;
	border-radius: 15px 0px;
	background-color: #005596;
	box-shadow: none;
	transition: all 600ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 0.85rem;
	font-weight: 400;
	text-transform: none;
	display: inline-block;
	text-decoration: none;
	text-align: center;
	border-bottom: none;
}

.primary:hover,
.js-submit-button.cms_form_button.primary:hover,
.primary-button:hover {
	border-radius: 0px 15px;
	background-color: #f1af19 !important;
	color: #fff;
}

.secondary,
.secondary-button {
	display: inline-block;
	margin-top: 0px;
	padding: 0.75rem 2rem;
	border-radius: 15px 0px;
	background-color: #f1af19;
	box-shadow: none;
	-webkit-transition: all 800ms ease;
	transition: all 800ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #273374;
	font-size: 0.85rem;
	font-weight: 400;
	text-decoration: none;
	text-transform: none;
	border-bottom: none;
}

.secondary:hover,
.secondary-button:hover {
	border-radius: 0px 15px;
	background-color: #005596;
	color: #fff;
}

.secondary-button.sign-in {
	-webkit-transition-duration: 800ms;
	transition-duration: 800ms;
}

.secondary-button.prescribe-now {
	margin-top: 1rem;
}

.tertiary,
.tertiary-button {
	display: inline-block;
	margin-top: 0px;
	padding: 0.75rem 2rem;
	border-radius: 20px 0px;
	background-color: #f1f1f1;
	box-shadow: none;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #273374;
	font-size: 0.85rem;
	font-weight: 400;
	text-decoration: none;
	text-transform: none;
}

.tertiary:hover,
.tertiary-button:hover {
	background-color: #005596;
	color: #fff;
}

.tertiary-button.copy-to-clipboard {
	padding: 0rem;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	border-bottom: 1px solid rgba(39, 51, 116, 0.15);
	border-radius: 0px;
	background-color: transparent;
	-webkit-transition-duration: 300ms;
	transition-duration: 300ms;
}

.tertiary-button.copy-to-clipboard:hover {
	border-bottom-style: solid;
	border-bottom-color: transparent;
	background-color: transparent;
	color: #273374;
}

.access-code {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 350px;
	margin-right: 0rem;
	margin-bottom: 1.5rem;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: transparent;
}

.text-field {
	margin-bottom: 0px;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(51, 51, 51, 0.25);
	text-align: left;
}

.text-field.access-code {
	border-style: none;
	background-color: transparent;
	text-align: center;
}

.field-text {
	margin-right: 1rem;
	margin-bottom: 0.125rem;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	font-weight: 400;
}

.login-form-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 3rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top-left-radius: 35px;
	border-bottom-right-radius: 35px;
	background-color: #f5f5f5;
}

.login-form-wrapper.access-code {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.select-field {
	margin-bottom: 0px;
	padding-right: 0.75px;
	padding-left: 0.75rem;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(51, 51, 51, 0.25);
	background-color: #fff;
}

.select-state-field {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 650px;
	margin-bottom: 1rem;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	background-color: transparent;
}

.enter-medical-number {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 650px;
	margin-bottom: 1rem;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	background-color: transparent;
}

.login-from-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.expert-content-wrap {
	width: 60%;
	margin-right: 0rem;
}

.expert-content-wrap.second {
	margin-right: 5rem;
}

.cb-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 1rem;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.cb-wrapper.centered {
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.video {
	display: none;
	width: 480px;
	height: 385px;
	max-height: none;
}

.thumbnail-video {
	width: 40%;
	height: auto;
	margin-right: 0rem;
	margin-left: 5rem;
	border-top-left-radius: 20px;
	border-bottom-right-radius: 20px;
}

.thumbnail-video.homepage {
	display: inline;
	overflow: visible;
	width: 100%;
	height: 350px;
	max-height: none;
	margin-left: auto;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-radius: 0px;
	-o-object-fit: contain;
	object-fit: contain;
	-o-object-position: 50% 50%;
	object-position: 50% 50%;
}

.list {
	width: 100%;
	margin-bottom: 0px;
	padding-left: 0px;
}

.enter-access-code {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 600px;
	margin-top: 0rem;
	margin-bottom: 1.5rem;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	background-color: transparent;
}

.div-block-90 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0rem;
	margin-bottom: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.enter-name {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 650px;
	margin-bottom: 1rem;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-color: transparent;
}

.login-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-right: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-right: 1px none rgba(51, 51, 51, 0.05);
}

.form-success {
	margin-top: 0rem;
	margin-bottom: 1.5rem;
	padding: 20px;
	border-top-left-radius: 0px;
	border-bottom-right-radius: 0px;
	background-color: #32880a;
	color: #fff;
	font-size: 1rem;
}

.sign-out-button {
	display: none;
	margin-top: 7px;
	padding: 0.75rem 2rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 20px 0px;
	background-color: #005596;
	box-shadow: none;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 0.85rem;
	font-weight: 400;
	text-transform: none;
	width: 120px;
	margin-left: 1.5rem;
}

.sign-out-button:hover {
	background-color: #f1af19;
}

.sign-out-button.prescribers-access-page {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.form-block {
	margin-bottom: 0px;
}

.asterisk {
	color: #ce0000;
}

.enter-specialty-number {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 650px;
	margin-bottom: 1.5rem;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	background-color: transparent;
}

.login-info-wrapper {
	padding: 3rem 0rem 0rem;
}

.form-text {
	display: block;
	width: 100%;
	margin-bottom: 1.5rem;
	padding-bottom: 0rem;
	padding-left: 0rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	font-family: 'Open Sans', sans-serif;
	color: #005596;
	font-size: 0.9rem;
	line-height: 1rem;
	font-weight: 600;
	text-align: left;
	text-transform: none;
}

.div-block-97 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 30%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.div-block-98 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
}

.div-block-99 {
	width: 70%;
}

.adobe-download-link {
	padding-left: 1.5rem;
	border-bottom: 0.5px solid rgba(0, 0, 0, 0.15);
	background-image: var(--pdf-icon);
	background-position: 0% 50%;
	background-size: 14px;
	background-repeat: no-repeat;
	font-family: 'Open Sans', sans-serif;
	color: #005596;
	font-size: 0.9rem;
	line-height: 1rem;
	font-weight: 600;
	text-decoration: none;
	text-transform: none;
}

.adobe-download-link:hover {
	border-bottom-style: none;
	border-bottom-color: #549fc4;
}

.adobe-download-link.w--current {
	color: #28313b;
	font-weight: 700;
}

.div-block-100 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.div-block-101 {
	margin-left: 2rem;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
}

.prescriber-click-here-link {
	display: inline-block;
	margin-right: 2rem;
	margin-bottom: 0rem;
	margin-left: 0rem;
	border-bottom: 1px solid #333;
	font-family: 'Open Sans', sans-serif;
	color: #333;
	font-size: 1rem;
	line-height: 1rem;
	font-weight: 400;
	text-decoration: none;
}

.prescriber-click-here-link:hover {
	border-bottom-color: #f1ae1b;
	color: #f1af19;
	text-decoration: none;
}

.enter-email {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 650px;
	margin-bottom: 1rem;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-color: transparent;
}

.additional-cb {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	height: auto;
	max-height: none;
	margin-right: 2rem;
	margin-left: 0px;
	padding: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	background-color: transparent;
}

.additional-cb.second {
	width: auto;
	margin-right: 0px;
	margin-left: 4rem;
	padding-right: 0rem;
	padding-left: 0rem;
	background-color: transparent;
}

.additional-content-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 90%;
	height: auto;
	margin-top: 1rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
}

.additional-content-wrapper.vertical-wrap {
	margin-top: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.additional-content-wrapper.second {
	margin-top: 1.5rem;
}

.additional-content-wrapper.about {
	display: none;
	width: auto;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
}

.h2-section-heading {
	margin-top: 0px;
	margin-bottom: 0px;
	font-family: 'Open Sans', sans-serif;
	color: #273374;
	font-size: 1.75rem;
	font-weight: 400;
}

.section-heading-wrapper {
	padding: 0.75rem 0.75rem 0.75rem 2rem;
	border-bottom: 0.75px solid rgba(51, 51, 51, 0.1);
}

.sidebar-list-item {
	margin-bottom: 0.5rem;
	padding-left: 0px;
}

.sidebar-section-wrapper {
	position: relative;
	margin-bottom: 2.441em;
	margin-left: 1.5rem;
	border-top: 1px none rgba(0, 0, 0, 0.15);
	font-size: 0.875rem;
	line-height: 1.5em;
}

.text-link {
	border-bottom: 1px solid #ddd;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #005596;
	font-size: 1rem;
	line-height: 1.3rem;
	text-decoration: none;
}

.text-link:hover {
	border-bottom-color: transparent;
	color: #0d70bd;
}

.text-link.w--current {
	color: #28313b;
	font-weight: 700;
}

.right-side-wrapper {
	border-left: 1px solid rgba(51, 51, 51, 0.05);
}

.sidebar-section-title {
	margin-top: 0rem;
	margin-bottom: 0.75rem;
	font-size: 1.25rem;
	line-height: 1.775rem;
}

.sidebar-section-title.h2 {
	font-family: 'Open Sans', sans-serif;
	color: #002c6d;
	line-height: 1.5rem;
	font-weight: 400;
}

.sidebar-list {
	padding-left: 0em;
}

.paragraph-2 {
	font-family: 'Open Sans', sans-serif;
	font-size: 0.85rem;
	line-height: 1.25rem;
}

.form-wrapper {
	margin-bottom: 0px;
}

.form-field-label {
	color: #333;
	font-weight: 600;
}

.form-field {
	position: relative;
	width: auto;
	margin-bottom: 0.75rem;
	padding: 4px 10px;
	font-size: 0.875rem;
}

.success,
.form-success-msg {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #32880a;
	color: #fff;
}

.error,
.form-error-msg {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #c33023;
	color: #fff;
}

.button-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 240px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.button-wrapper.access-code {
	width: 275px;
}

.slider-arrow {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 45px;
	height: 45px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-style: none;
	border-width: 2px;
	border-color: #005596;
	border-radius: 100px;
	background-color: #005596;
	-webkit-transition: all 300ms ease, background-color 300ms ease;
	transition: all 300ms ease, background-color 300ms ease;
}

.slider-arrow:hover {
	border-style: none;
	border-color: #005596;
	background-color: #f1ae1b;
	-webkit-transform: scale(1.05);
	-ms-transform: scale(1.05);
	transform: scale(1.05);
}

.sub-title-text-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	height: auto;
	padding: 1rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border-radius: 10px 0px;
	background-color: transparent;
}

.b-a-slide {
	overflow: visible;
	margin: 0rem;
	background-color: #fff;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.b-a-slider .left-arrow {
	left: auto;
	top: -65px;
	right: 9rem;
	bottom: 0%;
	z-index: 1000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100px;
	margin-top: 0px;
	margin-right: 0px;
	margin-left: 0px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.left-arrow.success-story {
	left: -5rem;
	top: auto;
	right: auto;
	bottom: auto;
	z-index: auto;
	overflow: hidden;
	height: 60px;
	margin-right: 0rem;
	padding-right: 0rem;
	padding-left: 1.5rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.b-a-slider {
	display: flex;
	flex-grow: 1;
	overflow: visible;
	width: 50%;
	margin-left: 0rem;
	padding: 0rem;
	justify-content: center;
	align-items: center;
	background-color: transparent;
}

.b-a-slider.prescriber-form-page {
	left: -3.5rem;
	width: 40%;
	height: 250px;
	margin-left: 3.5rem;
}

.b-a-slide-mask {
	overflow: hidden;
	width: 100%;
}

.b-a-slider .right-arrow {
	top: -65px;
	right: 5rem;
	display: flex;
	height: 100px;
	margin-top: 0px;
	margin-bottom: auto;
	justify-content: flex-end;
	align-items: flex-start;
}

.right-arrow.success-story {
	left: auto;
	top: auto;
	right: -5rem;
	bottom: auto;
	height: 60px;
	margin-right: 0rem;
	margin-bottom: 0px;
	padding-right: 1.5rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.slide-nav {
	bottom: auto;
	display: none;
	font-size: 0.5rem;
}

.slider-image {
	width: auto;
	height: auto;
	padding: 1rem 1rem 0.5rem;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: scroll;
	cursor: pointer;
}

.b-a-slider-wrapper {
	position: relative;
	overflow: visible;
	height: auto;
}

.header-cb-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 44vh;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.formula-image {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 150px;
	margin-right: 1rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.formula-image-two {
	width: 105px;
}

.header-cb {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 50%;
	padding: 3rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	background-color: #fff;
}

.header-cb.call-out {
	position: static;
	left: auto;
	top: auto;
	right: 608px;
	bottom: 0%;
	width: 500px;
	height: 125px;
	padding: 2.5rem 2rem;
	flex-direction: row;
	border-top-left-radius: 35px;
	background-color: #f1af19;
}

.header-cb.call-out.white {
	position: static;
	right: 16.1rem;
	padding: 2.5rem 2rem;
	flex-direction: column;
	justify-content: center;
	border-top-left-radius: 0px;
	border-top-right-radius: 35px;
	background-color: #fff;
}

.image-wrapper {
	display: flex;
	flex-direction: row;
}

.header-call-out {
	font-family: 'Open Sans', sans-serif;
	color: #273374;
	font-size: 1.4rem;
	line-height: 1.75rem;
	font-weight: 400;
	text-align: center;
}

.header-call-out._2 {
	margin-bottom: 0.5rem;
	color: #273374;
}

.video-and-slider-inner-content {
	display: flex;
	width: 100%;
	margin-top: 1rem;
	justify-content: space-between;
}

.video-and-slider-wrap {
	display: flex;
	width: 100%;
	justify-content: center;
}

.lightbox-link p,
.lightbox-link {
	display: flex;
	flex-grow: 1;
	width: 100%;
	margin-right: 0rem;
	justify-content: center;
	align-items: center;
}

.lightbox-link {
	padding-right: 40px;
	max-width: 50%;
}

.image {
	width: 100%;
}

.video-icon-image {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: visible;
	width: 100%;
	height: 80px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
}

.play-icon {
	width: 60px;
	height: 60px;
}

.video-content {
	position: absolute;
	z-index: 1;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	height: auto;
	margin-top: 0px;
	padding-top: 0px;
	padding-bottom: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
}

.video-and-slider-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.b-a-slider {
	padding-left: 40px;
}

.access-form-buttons {
	display: flex;
	justify-content: center;
	align-items: center;
}

.access-form-buttons .primary,
.access-form-buttons .secondary {
	margin-left: 10px;
}

#email-form #submissionforms_module {
	display: flex;
	width: 60%;
	flex-direction: column;
}

.expert-img {
	width: 50%;
	margin-right: 2rem;
}

/* Begin flex items in nav */
#navbar-flex-items {
	display: flex;
	justify-content: space-between;
}

#logo-and-notice {
	display: flex;
}

#fda-notice {
	min-width: 290px;
	max-width: 300px;
	margin-left: 2rem;
	font-family: 'Open Sans', sans-serif;
	font-size: 0.75rem;
	line-height: 1rem;
	margin-bottom: 0pc;
}
/* End flex items in nav */

/* Begin alert */
.alert-exit-icon {
	position: absolute;
	top: auto;
	right: 1.5rem;
	z-index: 500;
	width: 25px;
	height: 25px;
	margin-top: 0em;
	margin-right: 0%;
	margin-left: auto;
	padding-right: 15px;
	padding-left: 0px;
	float: right;
	border-bottom-style: none;
	background-image: url("https://assets.website-files.com/614b5d5ca9e2a671a6d13d6c/614b5f351b83b982fb28f9fd_alert-exit-icon.svg");
	background-position: 50% 50%;
	background-size: 12px 12px;
	background-repeat: no-repeat;
	transition: all 300ms ease;
	color: hsla(0, 0%, 100%, 0.8);
	font-size: 0.75rem;
	line-height: 1.5em;
	letter-spacing: 0.05em;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
}

.alert-header-inner-text a {
	margin-bottom: 0;
	color: #273374;
}

.alert-header-inner-text a:hover {
	border-bottom: 1px solid transparent;
	color: #273374;
}

.alert-exit-icon:hover {
	color: #fff;
}

.alert-link {
	border-bottom: 1px solid #273374;
	transition: all 0ms ease;
	font-family: Lato, sans-serif;
	color: #273374;
	font-size: 0.975rem;
	line-height: 1.15rem;
	font-weight: 400;
	text-decoration: none;
	cursor: pointer;
}

.alert-link:hover {
	border-bottom-color: transparent;
}

.alert-link.first-alert-link {
	margin-right: 1rem;
	border-bottom-color: #273374;
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
	color: #273374;
	font-size: 0.975rem;
	line-height: 1.15rem;
}

.alert-link.first-alert-link:hover {
	border-bottom-color: transparent;
}

.alert-header-section {
	position: static;
	left: 0%;
	top: 85px;
	right: 0%;
	bottom: auto;
	z-index: 10;
	display: none;
	overflow: hidden;
	justify-content: space-between;
	background-color: #fff;
}

.alert-header-content-wrapper {
	position: relative;
	display: flex;
	padding: 0.75rem 2em;
	align-items: center;
	font-size: 0.875em;
}

.alert-container {
	position: relative;
	display: flex;
	max-width: none;
	padding-right: 1.5rem;
	padding-left: 1.5rem;
	justify-content: space-between;
	align-items: center;
}

.alert-header-inner-text {
	margin-top: 0em;
	margin-right: 1rem;
	margin-bottom: 0em;
	font-family: Lato, sans-serif;
	color: #273374;
	font-size: 0.975rem;
	line-height: 1.25rem;
	font-weight: 700;
	letter-spacing: 0px;
	margin-right: 0.25rem;
	font-weight: 400;
}

.aleart-header-wrapper {
	position: relative;
	display: block;
	width: 100%;
	padding-top: 0px;
	justify-content: space-around;
	align-items: center;
	background-color: rgba(13, 112, 189, 0.15);
	color: #fff;
}

.alert-header-text {
	position: relative;
	display: flex;
	align-items: center;
	align-self: center;
	flex-grow: 1;
	flex-shrink: 1;
}

.alert-header-text p {
	margin-bottom: 0;
}
/* End alert */

@media screen and (max-width: 1060px) {
	.alert-header-section {
		top: 100px;
	}
}

@media screen and (max-width: 991px) {
	.alert-header-section {
		top: 153px;
	}

	#doctor-section {
		flex-direction: column;
	}

	#doctor-section .expert-img {
		width: 100%;
	}

	#doctor-section .expert-img img {
		width: 100%;
		margin-right: 0;
		margin-bottom: 1rem;
	}

	#doctor-section .expert-content-wrap {
		width: 100%;
	}

	#navbar-flex-items {
		align-items: center;
	}

	#logo-and-notice {
		flex-direction: column;
	}

	#fda-notice {
		margin-left: 0;
		margin-top: 0;
	}

	.logo-img-wrapper {
		width: 225px;
		margin-right: auto;
		margin-bottom: 0.5rem;
		margin-left: 0rem;
	}

	.lightbox-link p,
	.lightbox-link {
		width: 425px;
		max-width: unset;
	}

	#email-form #submissionforms_module {
		width: 80%;
	}

	.b-a-slider,
	.lightbox-link {
		padding: 0;
	}

	#navbar-flex-items {
		padding-right: 2rem;
	}

	.login {
		display: flex;
		width: 165px;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.login.prescribe-now {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 165px;
		margin-top: 0px;
	}

	.h3-heading {
		font-size: 1.4rem;
	}

	.h3-heading.blue {
		font-size: 1.5rem;
		line-height: 1.8rem;
	}

	.content-wrapper {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}

	.content-wrapper.user-access {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}

	.content-wrapper.testimonials {
		padding-top: 3rem;
		padding-bottom: 2.5rem;
	}

	.content-wrapper.testimonials.login-page {
		padding-top: 1rem;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.content-wrapper.about {
		padding: 3rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.heading-2 {
		max-width: 600px;
		font-size: 2.15rem;
	}

	.footer-section {
		padding-right: 3rem;
		padding-left: 3rem;
	}

	.back-to-top-link {
		background-size: auto 14px;
	}

	.acrobat-reader-link {
		margin-right: 2rem;
		margin-bottom: 0rem;
		font-size: 0.75rem;
		text-align: center;
	}

	.footer-signoff {
		display: block;
		padding-right: 0px;
		padding-left: 0px;
		padding-top: 1rem;
		justify-content: center;
		flex-wrap: nowrap;
		border-top-color: rgba(0, 0, 0, 0.15);
	}

	.address-para {
		margin-bottom: 1rem;
		line-height: 1.25rem;
	}

	.footer-left-content-wrap {
		width: 45%;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		border-bottom: 1px none rgba(255, 248, 225, 0.25);
	}

	.footer-right-content-wrap {
		width: 100%;
		padding-top: 0rem;
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
	}

	.cb-static.footer-cb-left {
		width: auto;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.social-media-wrapper {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-container {
		max-width: 991px;
	}

	.footer-container.footer-content-container {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		border-bottom-style: solid;
	}

	.social-media-link {
		width: 2rem;
		height: 2rem;
		margin-right: 0rem;
		margin-bottom: 0rem;
		margin-left: 0rem;
		background-color: transparent;
	}

	.social-media-link.youtube {
		margin-right: 0rem;
		margin-bottom: 0rem;
	}

	.footer-right-col {
		width: 25%;
		padding-right: 0vw;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.sub-title-text.slider {
		padding-right: 0.75rem;
		padding-left: 0.75rem;
		font-size: 0.6rem;
	}

	.slider {
		margin-right: 4rem;
	}

	.slider-navigation {
		top: auto;
	}

	.testimonial-slide {
		width: auto;
		padding: 1rem 4rem 2rem;
	}

	.slide-mask {
		width: 100%;
	}

	.arrow-slider {
		width: 35px;
		height: 35px;
	}

	.slider-wrapper {
		width: 85vw;
	}

	.slider-content {
		width: 100%;
	}

	.slider-content.inside-page {
		height: auto;
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.testimonial-slider {
		padding-left: 0rem;
	}

	.article-cb-content-wrap {
		margin-right: 0rem;
		margin-bottom: 2rem;
	}

	.nav-menu {
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		align-items: flex-end;
		margin-right: 2rem;
	}

	.left-nav-list-content-wrap {
		overflow: hidden;
		height: 0px;
	}

	.mobile-menu-button {
		margin-left: 1rem;
	}

	.content-container {
		width: 100%;
		padding-left: 0rem;
	}

	.content-container.threequarterwidth {
		width: 100%;
		padding-left: 0rem;
	}

	.main-content-column {
		padding-right: 0px;
		padding-left: 0px;
	}

	.left-nav-list {
		margin-bottom: 1.5em;
	}

	.main-content-wrapper {
		width: 100%;
	}

	.inside-page-bg-img {
		height: 175px;
		margin-right: 0px;
		margin-left: 0px;
		padding-right: 0px;
		padding-left: 0px;
	}

	.inside-content-background {
		max-width: 100%;
		padding: 2rem 3rem;
	}

	.inside-img-wrap {
		width: 100%;
		height: 300px;
	}

	.inside-page-breadcrumb-wrap {
		width: 100%;
		margin-bottom: 2rem;
		padding-bottom: 1rem;
		border-bottom-style: solid;
		border-bottom-color: rgba(51, 51, 51, 0.05);
	}

	.paragraph {
		text-align: left;
	}

	.paragraph.no-padding {
		text-align: left;
	}

	.select-state-field {
		width: 600px;
	}

	.enter-medical-number {
		width: 600px;
	}

	.cb-wrapper {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.video {
		height: 250px;
	}

	.thumbnail-video {
		margin-left: 2rem;
	}

	.thumbnail-video.homepage {
		max-height: none;
	}

	.enter-name {
		width: 600px;
	}

	.enter-specialty-number {
		width: 600px;
	}

	.div-block-100 {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.hamburger-menu-icon {
		color: #005596;
		font-size: 2rem;
	}

	.menu-button {
		display: none;
		padding: 0.5rem;
	}

	.prescriber-click-here-link {
		margin-right: 2rem;
		margin-bottom: 0rem;
		font-size: 1rem;
		text-align: center;
	}

	.enter-email {
		width: 600px;
	}

	.additional-cb {
		width: 100%;
		margin-right: 0rem;
		padding-top: 0rem;
		padding-bottom: 0rem;
		padding-left: 0rem;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
	}

	.additional-cb.second {
		width: 100%;
		margin-right: 0px;
		padding-top: 0rem;
		padding-bottom: 0rem;
		padding-left: 0rem;
	}

	.additional-content-wrapper {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.additional-content-wrapper.second {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.additional-content-wrapper.about {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.sidebar-section-wrapper {
		margin-bottom: 2rem;
		margin-left: 0em;
	}

	.right-side-wrapper {
		border-left-style: none;
	}

	.slider-arrow {
		width: 35px;
		height: 35px;
	}

	.sub-title-text-wrapper {
		padding: 0.5rem;
	}

	.b-a-slide {
		margin-bottom: 0rem;
	}

	.left-arrow.success-story {
		left: -3rem;
		top: auto;
		right: auto;
		bottom: auto;
		margin-right: 0rem;
		padding-left: 0.5rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.b-a-slider {
		width: 425px;
		margin-left: 0px;
		padding-right: 0rem;
		padding-bottom: 0rem;
		padding-left: 0rem;
	}

	.b-a-slider.prescriber-form-page {
		left: -2rem;
		margin-left: 1rem;
	}

	.b-a-slide-mask {
		width: 100%;
	}

	.b-a-slider .right-arrow {
		right: 0rem;
	}

	.right-arrow.success-story {
		top: auto;
		right: -3rem;
		bottom: auto;
		margin-right: 0rem;
		margin-left: auto;
		padding-right: 0.5rem;
	}

	.slider-image {
		width: auto;
		height: 250px;
	}

	.header-cb.call-out {
		width: 350px;
	}

	.header-call-out {
		font-size: 1.3rem;
		line-height: 1.7rem;
	}

	.header-call-out._2 {
		margin-bottom: 0.5rem;
		font-size: 1.3rem;
		line-height: 1.7rem;
	}

	.video-and-slider-inner-content {
		width: 95%;
		flex-direction: column;
		align-items: center;
	}

	.video-and-slider-wrap {
		display: flex;
		justify-content: center;
	}

	.lightbox-link {
		justify-content: center;
	}

	.image {
		height: 100%;
	}

	.video-icon-image {
		width: 70px;
		height: 70px;
	}

	.play-icon {
		width: 70px;
		height: 70px;
	}

	.video-content {
		height: auto;
		padding: 25px;
	}

	.login.prescribe-now {
		margin-right: 0;
		margin-top: 0.5rem;
	}
}

@media screen and (max-width: 767px) {
	.heading-and-subtitle-wrapper.access-form-container {
		width: 90%;
	}

	.article-cb .h3-heading p {
		text-align: left;
	}

	.secondary-button.prescribe-now {
		margin-bottom: 2rem;
	}

	#email-form #submissionforms_module {
		width: 90%;
	}

	.mobile-friendly-image {
		display: block;
		float: none !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}

	.hero-img {
		height: 40vh;
	}

	.logo-img-wrapper {
		width: 225px;
		margin-right: 1rem;
	}

	#navbar-flex-items {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		justify-content: space-between;
		padding-right: 1.5em;
		padding-left: 2rem;
	}

	.nav-menu {
		width: auto;
		margin-left: 0px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		margin-right: 0;
	}

	#logo-and-notice {
		margin-left: 0rem;
		padding-right: 0;
		padding-left: 0rem;
		justify-content: space-between;
		order: -1;
		margin-right: auto;
	}

	.mobile-menu-button {
		margin-left: auto;
	}

	.login {
		width: 150px;
		margin-top: 0.5rem;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
		font-size: 0.8rem;
	}

	.login.prescribe-now {
		width: 150px;
		margin-top: 0;
		margin-right: 0;
	}

	.h3-heading.blue {
		text-align: center;
	}

	.content-wrapper.testimonials {
		padding-bottom: 2rem;
	}

	.content-wrapper.about {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}

	.footer-section {
		padding-right: 2.5rem;
		padding-left: 2.5rem;
	}

	.footer-signoff-text {
		display: block;
		text-align: left;
	}

	.acrobat-reader-link {
		font-size: 0.75rem;
		line-height: 1.25rem;
		text-align: left;
	}

	.footer-signoff {
		padding-right: 0px;
		padding-left: 0px;
	}

	.address-para {
		margin-bottom: 1rem;
		line-height: 1.1rem;
	}

	.login-wrapper {
		margin-top: 1rem;
	}

	.wrapper {
		width: auto;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.prescribers-login {
		margin-bottom: 1rem;
	}

	.author-name,
	.attribution {
		padding-left: 0rem;
	}

	.footer-left-content-wrap {
		width: 100%;
		border-bottom: 1px solid rgba(255, 248, 225, 0.25);
	}

	.footer-right-content-wrap {
		padding-top: 1rem;
		padding-bottom: 0rem;
		border-bottom: 1px none rgba(255, 248, 225, 0.25);
	}

	.cb-static {
		width: 33.33%;
	}

	.cb-static.footer-cb-left {
		width: 100%;
		padding-bottom: 0rem;
	}

	.social-media-wrapper {
		margin-bottom: 1rem;
	}

	.footer-container {
		max-width: 767px;
	}

	.footer-container.footer-content-container {
		padding-top: 0rem;
		padding-bottom: 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.social-media-link {
		margin-right: 0rem;
		margin-left: 0rem;
	}

	.footer-right-col {
		width: 30%;
		padding-bottom: 0rem;
	}

	.footer-signoff-wrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.footer-left-col {
		margin-bottom: 0.5rem;
	}

	.slider-navigation {
		top: auto;
	}

	.slide-mask {
		width: 100%;
	}

	.slider-wrapper {
		width: 90vw;
	}

	.slider-content {
		height: auto;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.slider-content.inside-page {
		height: auto;
	}

	.article-cb-content-wrap {
		width: 100%;
	}

	.footer-middle-col {
		margin-bottom: 1rem;
	}

	.intro-paragraph {
		font-size: 1.125rem;
	}

	.content-container.threequarterwidth {
		padding-right: 0rem;
	}

	h2 a,
	.h2-header-link {
		font-size: 1.75rem;
	}

	h3 a,
	.h3-header-link {
		font-size: 1.5rem;
	}

	h4 a,
	.h4-header-link {
		font-size: 1.25rem;
	}

	h5 a,
	.h5-header-link {
		font-size: 1.125rem;
	}

	blockquote,
	.block-quote {
		font-size: 1.125rem;
		line-height: 1.5rem;
	}

	.ordered-list-2 {
		padding-left: 20px;
	}

	.main-content-section {
		margin-right: 0rem;
	}

	.inside-page-bg-img {
		min-height: 80px;
	}

	.inside-content-background {
		padding: 2rem 2.5rem;
	}

	.inside-page-breadcrumb-wrap {
		padding-right: 0px;
		padding-left: 0px;
	}

	.unordered-list {
		padding-left: 20px;
	}

	.paragraph.no-padding {
		text-align: center;
	}

	.primary,
	.primary-button {
		margin-top: 2px;
	}

	.secondary-button {
		margin-top: 2px;
	}

	.tertiary-button {
		margin-top: 2px;
	}

	.access-code {
		width: 100%;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.login-form-wrapper {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.select-state-field {
		width: 100%;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.enter-medical-number {
		width: 100%;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.expert-img {
		width: 100%;
		margin-right: 0rem;
		margin-bottom: 1rem;
	}

	.expert-content-wrap {
		width: 100%;
	}

	.expert-content-wrap.second {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-align-self: auto;
		-ms-flex-item-align: auto;
		align-self: auto;
	}

	.cb-wrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.thumbnail-video {
		width: 100%;
		margin-bottom: 1rem;
		margin-left: 0rem;
	}

	.enter-access-code {
		width: 100%;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.enter-name {
		width: 100%;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.form-success {
		font-size: 0.9rem;
	}

	.sign-out-button {
		margin-top: 7px;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
		font-size: 0.8rem;
	}

	.enter-specialty-number {
		width: 100%;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.form-text {
		padding-left: 0rem;
	}

	.div-block-97 {
		width: 50%;
	}

	.div-block-99 {
		width: 50%;
	}

	.div-block-100 {
		margin-bottom: 2rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.div-block-101 {
		margin-left: 0rem;
	}

	.prescriber-click-here-link {
		font-size: 1rem;
		line-height: 1.25rem;
		text-align: left;
	}

	.enter-email {
		width: 100%;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.additional-cb {
		width: 100%;
		padding: 0rem;
	}

	.additional-cb.second {
		width: 100%;
		margin-left: 0rem;
	}

	.additional-content-wrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.sidebar-section-wrapper {
		margin-left: 0rem;
	}

	.left-arrow.success-story {
		left: -3.25rem;
	}

	.b-a-slider {
		height: 250px;
	}

	.b-a-slider.prescriber-form-page {
		left: 0rem;
		width: 80%;
		margin-top: 2rem;
		margin-left: 0rem;
	}

	.right-arrow.success-story {
		right: -3.25rem;
	}

	.slider-image {
		background-position: 50% 50%;
		background-size: cover;
		background-repeat: no-repeat;
		background-attachment: scroll;
	}

	.header-cb-wrapper {
		height: 40vh;
	}

	.formula-image {
		width: 105px;
		margin-right: 0.5rem;
		margin-bottom: 0rem;
	}

	.formula-image-two {
		width: 75px;
	}

	.header-cb.call-out {
		width: 250px;
		height: 125px;
		padding: 2rem 1rem;
	}

	.header-cb.call-out.white {
		height: 125px;
		padding: 2rem 1rem;
	}

	.image-wrapper {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.header-call-out {
		font-size: 1rem;
		line-height: 1.3rem;
	}

	.header-call-out._2 {
		margin-bottom: 1rem;
		font-size: 1rem;
		line-height: 1.3rem;
	}

	.video-and-slider-inner-content {
		flex-direction: column;
	}

	.lightbox-link {
		width: 420px;
		margin-bottom: 1rem;
	}

	.video-icon-image {
		width: 60px;
		height: 60px;
	}

	.play-icon {
		width: 60px;
		height: 60px;
	}

	.video-content {
		height: auto;
	}

	.alert-header-section {
		top: 185px;
	}

	#fda-notice {
		max-width: 250px;
		font-size: 0.7rem;
		line-height: 0.9rem;
		min-width: auto;
		margin-bottom: 0px;
	}
}

@media screen and (max-width: 560px) {
	.b-a-slider {
		width: 100%; /* Neccessary so the slideshow will use the same breakpoints that the video beside it, which is using FitVids, is using */
	}
}

@media screen and (max-width: 479px) {
	.logo-img-wrapper {
		margin-right: unset !important;
	}

	#logo-and-notice {
		align-items: flex-start;
		padding-right: 0.5rem;
		padding-left: 1rem;
		margin-bottom: 0.75rem;
	}

	#fda-notice {
		text-align: left;
		font-size: 0.7rem;
		line-height: 0.9rem;
		max-width: 225px;
	}

	p,
	#prescriber-login-notice {
		text-align: center;
	}

	.expert-img {
		margin-right: 0;
		width: 100%;
	}

	.lightbox-link {
		width: 300px;
		margin-bottom: 7rem;
	}

	.access-form-buttons {
		flex-direction: column;
	}

	.access-form-buttons .primary,
	.access-form-buttons .secondary {
		margin-left: 0;
	}

	.hero-img {
		height: 50vh;
	}

	.logo-img-wrapper {
		width: 150px;
		margin-right: auto;
		margin-bottom: 0.5rem;
	}

	.navbar {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	#navbar-flex-items {
		padding-right: 0.75rem;
		padding-left: 0.75rem;
	}

	.login {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 135px;
		margin-top: 0.5rem;
		padding-right: 0.75rem;
		padding-left: 0.75rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		font-size: 0.7rem;
		line-height: 1rem;
		font-weight: 400;
		height: 40px;
	}

	.login.prescribe-now {
		width: 135px;
		margin-top: 0.5rem;
		margin-right: 0.5rem;
	}

	.h3-heading {
		font-size: 1.15rem;
		line-height: 1.3rem;
	}

	.h3-heading.blue {
		font-size: 1.3rem;
		line-height: 1.5rem;
		text-align: center;
	}

	.content-wrapper {
		padding: 2.5rem 2rem;
	}

	.content-wrapper.user-access {
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}

	.content-wrapper.testimonials {
		padding-top: 2.5rem;
		padding-bottom: 1.5rem;
	}

	.content-wrapper.testimonials.login-page {
		padding-top: 0rem;
	}

	.content-wrapper.about {
		padding: 2.5rem;
	}

	.heading-2 {
		margin-bottom: 0.5rem;
		font-size: 2rem;
		text-align: center;
	}

	.heading-2.meet-the-expert {
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
		text-align: center;
	}

	.footer-section {
		padding-bottom: 1rem;
	}

	.footer-signoff-text {
		margin-right: 0rem;
		padding-bottom: 0.25rem;
		font-size: 0.7rem;
		line-height: 1rem;
		text-align: center;
	}

	.additional-content-wrapper .owl-carousel .owl-nav button {
		bottom: 0;
		top: unset;
	}

	.additional-content-wrapper .owl-carousel .owl-nav button.owl-prev {
		left: 0%;
	}

	.additional-content-wrapper .owl-carousel .owl-nav button.owl-next {
		right: 0%;
	}

	.acrobat-reader-link {
		margin-right: 0rem;
		margin-bottom: 0.25rem;
		font-size: 0.7rem;
	}

	.footer-signoff {
		margin-top: 0rem;
		padding-right: 15px;
		padding-left: 15px;
	}

	.address-para {
		text-align: center;
	}

	.author-name,
	.attribution {
		padding-bottom: 0rem;
		padding-left: 0rem;
		text-align: center;
	}

	.left-arrow-wrapper {
		display: none;
		padding-left: 1rem;
	}

	.right-arrow-wrapper {
		display: none;
		padding-right: 1rem;
	}

	.footer-left-content-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-right-content-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		border-bottom-color: rgba(255, 248, 225, 0.25);
	}

	.cb-static.footer-cb-left {
		width: 100%;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-container {
		max-width: none;
	}

	.footer-container.footer-content-container {
		text-align: center;
	}

	.footer-right-col {
		width: 100%;
		padding-right: 0px;
		padding-bottom: 0rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.footer-left-col {
		display: block;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.sub-title-text.slider {
		padding-right: 0.4rem;
		padding-left: 0.4rem;
		font-size: 0.55rem;
	}

	.slider {
		margin-right: 4rem;
		margin-bottom: 2rem;
	}

	.slider-navigation {
		top: auto;
		display: block;
	}

	.testimonial-slide {
		padding-right: 2rem;
		padding-bottom: 1rem;
		padding-left: 2rem;
	}

	.arrow-slider {
		border-style: none;
	}

	.slider-wrapper {
		width: 100vw;
	}

	.slider-content {
		height: auto;
		padding-right: 0.25rem;
		padding-left: 0.25rem;
	}

	.slider-content.inside-page {
		height: auto;
		padding-right: 0.25rem;
		padding-left: 0.25rem;
	}

	.position-title {
		text-align: center;
	}

	.footer-middle-col {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.nav-menu {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-left: 0px;
		flex-direction: row;
		justify-content: center;
		flex-wrap: wrap;
		align-items: center;
		width: 100%;
		margin-left: 0px;
		padding-top: 0.25rem;
		order: 1;
		border-top: 1px solid rgba(51, 51, 51, 0.15);
	}

	.intro-paragraph {
		line-height: 1.5rem;
		text-align: center;
	}

	#navbar-flex-items {
		padding-left: 0px;
		padding-right: 0px;
	}

	.menu-button {
		margin-right: 0.5rem;
	}

	.paragraph-text-2 {
		font-size: 0.9rem;
	}

	.paragraph-text-2.list-item {
		text-align: center;
	}

	.content-container.threequarterwidth {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.text-block-21 {
		color: #005596;
		font-size: 0.9rem;
	}

	h1,
	.heading-6 {
		font-size: 2rem;
		line-height: 2.5rem;
		text-align: center;
	}

	h2,
	h3,
	h4,
	.heading-18 {
		text-align: center;
	}

	h3,
	.h3-header-link {
		display: inline-block;
		justify-content: center;
		align-self: center;
		text-align: center;
	}

	h4,
	h5,
	h6,
	h4 a,
	h5 a,
	h6 a,
	.h4-header-link,
	.h5-header-link,
	.h6-header-link,
	.heading-10 {
		align-self: center;
		text-align: center;
	}

	.horizontal-divider {
		display: block;
	}

	.horizontal-divider.second {
		margin-top: 5rem;
	}

	.h2-header-link {
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
		text-align: center;
	}

	.button-styling {
		width: auto;
		height: 175px;
		padding-left: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.block-quote {
		-webkit-align-self: auto;
		-ms-flex-item-align: auto;
		-ms-grid-row-align: auto;
		align-self: auto;
	}

	.ordered-list-2 {
		-webkit-align-self: auto;
		-ms-flex-item-align: auto;
		-ms-grid-row-align: auto;
		align-self: auto;
	}

	.main-content-section.full-page {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.inside-page-bg-img {
		height: 125px;
	}

	.inside-content-background {
		padding: 2rem 1.5rem;
	}

	.inside-img-wrap {
		width: 100%;
		height: 300px;
	}

	.paragraph {
		font-size: 0.9rem;
		line-height: 1.25rem;
		text-align: center;
	}

	.paragraph.centered {
		line-height: 1.25rem;
	}

	.paragraph.centered.required-field {
		margin-bottom: 0.5rem;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.paragraph.no-padding {
		line-height: 1.25rem;
		text-align: center;
	}

	.paragraph.no-padding.prescribe-now {
		text-align: center;
	}

	.primary,
	.primary-button {
		margin-top: 0px;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
		font-size: 0.7rem;
		line-height: 1rem;
		font-weight: 400;
	}

	.secondary-button {
		margin-top: 0px;
		padding-right: 2rem;
		padding-left: 2rem;
		font-size: 0.85rem;
		line-height: 1rem;
		font-weight: 400;
	}

	.secondary-button.sign-in {
		padding-right: 2rem;
		padding-left: 2rem;
		font-size: 0.85rem;
	}

	.tertiary-button {
		margin-top: 0px;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
		font-size: 0.7rem;
		line-height: 1rem;
		font-weight: 400;
	}

	.access-code {
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.text-field.access-code {
		padding-top: 0px;
		padding-bottom: 0px;
	}

	.field-text {
		margin-right: 0rem;
		margin-bottom: 0.5rem;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.login-form-wrapper {
		padding-top: 2rem;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.login-form-wrapper.access-code {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.select-state-field {
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.enter-medical-number {
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.enter-access-code {
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.enter-name {
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.sign-out-button {
		margin-top: 0.5rem;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
		font-size: 0.7rem;
		line-height: 1rem;
		font-weight: 400;
	}

	.sign-out-button.prescribers-access-page {
		font-size: 0.75rem;
	}

	.enter-specialty-number {
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.form-text {
		padding-bottom: 0rem;
		padding-left: 0rem;
	}

	.div-block-97 {
		width: 70%;
	}

	.div-block-99 {
		width: 30%;
	}

	.div-block-100 {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.div-block-101 {
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.menu-button {
		margin-left: auto;
	}

	.prescriber-click-here-link {
		margin-right: 0rem;
		margin-bottom: 0.25rem;
		font-size: 0.9rem;
	}

	.enter-email {
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.additional-cb {
		margin-top: 1rem;
		padding: 0.5rem 0rem;
	}

	.slider-arrow {
		border-style: none;
	}

	.sub-title-text-wrapper {
		padding: 0.4rem;
	}

	.b-a-slide {
		margin-right: 0rem;
	}

	.left-arrow.success-story {
		left: 0px;
		top: auto;
		right: auto;
		bottom: -50px;
		height: 40px;
		margin-right: 1rem;
	}

	.b-a-slider {
		height: 130px;
	}

	.b-a-slider.prescriber-form-page {
		width: 100%;
		height: 150px;
		margin-left: 0rem;
	}

	.right-arrow.success-story {
		top: auto;
		right: 0rem;
		bottom: -50px;
		height: 40px;
		margin-right: 0rem;
	}

	.header-cb-wrapper {
		height: 50vh;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.formula-image {
		width: 105px;
		margin-bottom: 0rem;
	}

	.formula-image-two {
		width: 75px;
	}

	.header-cb.call-out {
		width: 100%;
		height: 85px;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.header-cb.call-out.white {
		width: 100%;
		height: 100px;
		padding-top: 1rem;
		padding-bottom: 1rem;
		border-top-right-radius: 0px;
	}

	.image-wrapper {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.header-call-out {
		font-size: 1rem;
		line-height: 1.25rem;
	}

	.header-call-out._2 {
		margin-bottom: 0.75rem;
		font-size: 1rem;
		line-height: 1.25rem;
	}

	.video-and-slider-inner-content {
		width: 100%;
		padding-bottom: 3.25rem;
	}

	.video-icon-image {
		width: 35px;
		height: 35px;
	}

	.play-icon {
		width: 35px;
		height: 35px;
	}

	.video-content {
		padding-top: 25px;
	}

	.download_link_wrapper {
		display: flex;
		justify-content: center;
		margin-bottom: 20px;
	}
}

@media screen and (max-width: 684px) {
	.alert-header-section {
		top: 202px;
	}
}

@media screen and (max-width: 486px) {
	.alert-header-section {
		top: 219px;
	}
}

@media screen and (max-width: 479px) {
	.alert-header-section {
		top: 191px;
	}
}

@media screen and (max-width: 371px) {
	.alert-header-section {
		top: 191px;
	}
}

@media screen and (max-width: 361px) {
	.alert-header-section {
		top: 247px;
	}
}

@media screen and (max-width: 355px) {
	.alert-header-section {
		top: 264px;
	}
}

@media screen and (max-width: 280px) {
	.alert-header-section {
		top: 281px;
	}
}

@media screen and (max-width: 348px) {
	.lightbox-link {
		width: 200px;
	}
}

/* Custom styles */
/* GRIP UAT styles */
table.styled thead th,
table.styled tbody th,
table.styled th a,
table.styled.striped th a {
	font-weight: inherit;
	color: #fff;
	font-size: 1rem;
	line-height: 1.2rem;
	text-align: left;
}

table.styled td,
table.styled.striped td {
	font-size: 0.96rem;
	padding: 0.375rem 0.625rem;
}

table.styled th a:hover,
table.styled.striped th a:hover {
	text-decoration: none;
}

table.styled thead th {
	padding: 0.625rem 0.625rem;
	background-color: #273374;
}

table.styled tbody th {
	padding: 0.5rem 0.625rem;
	background-color: #005596;
}

table.styled.striped tr:nth-child(even) td {
	background: #dddddd;
}

table.styled.striped tr:nth-child(odd) td {
	background: #ffffff;
}

select,
textarea,
input[type="text"] {
	display: block;
	position: relative;
	width: 100%;
	margin-bottom: 0.75rem;
	padding: 8px 12px;
	font-size: 0.875rem;
	color: #333333;
	background-color: #ffffff;
	border: 1px solid #cccccc;
}
/* End GRIP UAT styles */

#printHeader,
#printFooter {
	display: none;
}

#GRIPFooterLogo {
	padding-top: 0 !important;
}

#GRIPFooterLogo a {
	border-bottom: none;
}

#GRIPFooterLogoText {
	margin-bottom: -4px;
}

.access-form-section {
	border-top-left-radius: 35px;
	border-bottom-right-radius: 35px;
	background-color: #f5f5f5;
	width: 100%;
	padding: 3rem;
	margin-bottom: 3rem;
}

.access-form-section h1 {
	font-size: 2.25rem;
}

#copyToClipboard {
	margin-right: 10px;
	color: #273374;
	font-size: 0.85rem;
}

#accessCode {
	margin-top: 2rem;
	margin-bottom: 2rem;
	color: #989898;
}

/* Select the table below the Access Code section */
.access-form-section ~ table .label-cell {
	font-weight: 600 !important;
	color: #005596;
}

.module-forms.confirmation .download_link_wrapper a.download_link {
	background-image: var(--pdf-icon);
}

.cms_label label {
	font-weight: normal;
}

.cms_buttons {
	text-align: center;
}

.video-and-slider-inner-content iframe,
.lightbox-link iframe {
	max-width: 100%;
	width: 700px;
	height: 300px !important;
	margin: auto;
}

#account_module {
	width: 100%;
}

#account_module .form_button.primary {
	border: none;
}

#account_module.form .form_item .label,
#account_module.form .form_item.required .label {
	white-space: nowrap;
	text-align: left;
}

#account_module.form .form_item.required .field {
	background: none;
}

#account_module.form .form_item .field {
	padding-left: 0;
}

#account_module .login_field {
	padding: 8px 12px;
	border: 1px solid #cccccc;
}

#account_module.form .form_container .login_field {
	width: 100%;
}

#account_module table.responsive td {
	display: block;
	width: 100%;
}

#account_module label[for="Password"] {
	font-weight: normal;
}

#prescriber-login-heading {
	margin-bottom: 15px;
}

#prescriber-login-notice {
	margin-bottom: 20px;
	font-size: 1rem;
}

.left-sidebar {
	margin-right: 1.5rem;
	margin-top: 1.5rem;
}

/* Sitemap styles */
ul li {
	margin-bottom: 10px;
}

ul li ul {
	margin-top: 10px;
}

ul li ul li {
	margin-bottom: 10px;
}
/* End sitemap styles */

#login-form-description {
	text-align: center;
}

#testimonials_module_contentbox {
	text-align: center;
}

#testimonials_module_contentbox .w-slide-testimonial {
	width: 100%;
}

.description-box {
	margin-bottom: 1.5rem;
}

#faqs_module .cms_list_subitem {
	line-height: 30px;
}

#patent-link {
	color: #273374;
}

/* End custom styles */

/* Mobile menu styles */
.mobile-navigation-menu li[data-breakpoints] {
	display: none !important;
}

.mobile-navigation-menu li[data-breakpoints*="xs,sm"] {
	display: flex !important;
}

@media (min-width: 992px) {
	.mobile-navigation-menu,
	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}

	.mobile-menu-button {
		display: none;
	}
}

@media screen and (min-width: 991px) {
	.mobile-navigation-menu li[data-breakpoints*="xl"] {
		display: flex !important;
	}
}

@media screen and (min-width: 767px) {
	.mobile-navigation-menu li[data-breakpoints*="md,lg"] {
		display: flex !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}

	.mobile-menu-button {
		display: block;
	}
}

.mobile-navigation-menu .mm-listitem,
.mobile-navigation-menu .mm-listitem .mm-btn {
	border-bottom: 0px;
}

.mobile-navigation-menu .mm-listitem .mm-btn {
	border-color: rgba(255, 255, 255, 0.09);
}

.mobile-navigation-menu .mm-listitem:hover,
.mobile-navigation-menu .mm-listitem.active {
	background-color: rgba(39, 51, 116, 0.25);
}

.mobile-navigation-menu .mm-listitem.mm-listitem_selected .mm-listitem__text {
	background-color: transparent;
}

.mobile-navigation-menu .mm-listitem:after {
	border-color: rgba(255, 255, 255, 0.09);
	left: 0px;
}

.mobile-navigation-menu .mm-listitem .mm-listitem__text,
.mobile-navigation-menu .mm-navbar__title {
	border-bottom: 0px;
}

.mobile-navigation-menu .mm-navbar__btn:not(.mm-hidden) + .mm-navbar__title:last-child {
	border-bottom: 0px;
}

/* Homepage update */
.header-cb-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 44vh;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.header-cb.call-out.left-col {
	width: 550px;
	height: 125px;
	padding: 1.5rem 1rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border: 1px solid #000;
	border-top-right-radius: 0px;
	background-color: #fff;
}

.header-cb.call-out.white {
	position: static;
	right: 16.1rem;
	width: 550px;
	height: 125px;
	padding: 1.5rem 1rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-style: solid solid solid none;
	border-width: 1px;
	border-color: #000;
	border-top-left-radius: 0px;
	border-top-right-radius: 35px;
	background-color: #fff;
}

.header-call-out-2 {
	margin-bottom: 1rem;
	font-family: 'Open Sans', sans-serif;
	color: #273374;
	font-size: 1.15rem;
	line-height: 1.35rem;
	font-weight: 400;
	text-align: center;
}

.prescribers-login {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 200px;
	margin-right: 1rem;
	margin-bottom: 0rem;
	margin-left: 0rem;
	padding: 0.75rem 2rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 15px 0px;
	background-color: #f1ae1b;
	box-shadow: none;
	-webkit-transition: all 800ms ease;
	transition: all 800ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #273374;
	font-size: 0.85rem;
	font-weight: 600;
}

.patients-login {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 200px;
	padding: 0.75rem 2rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-radius: 15px 0px;
	background-color: #f1ae1b;
	box-shadow: none;
	-webkit-transition: all 850ms ease;
	transition: all 850ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #273374;
	font-size: 0.85rem;
	font-weight: 600;
}

.video-and-slider-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.video-and-slider-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.video-and-slider-inner-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 85%;
	margin-top: 1rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	row-gap: 2rem;
}

.lightbox-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 46%;
	margin-right: 0rem;
	justify-content: center;
	align-items: center;
	padding-right: 0;
	flex-grow: 0;
	max-width: none;
}

.video-and-slider-inner-content iframe,
.lightbox-link iframe {
	height: 19vw !important;
}

.video-and-slider-inner-content .b-a-slider p:last-child,
.lightbox-link p:last-child {
	margin-bottom: 0px;
}

.b-a-slider {
	height: 100%;
	width: 46%;
	flex-grow: 0;
	padding-left: 0;
}

.b-a-slider.w-slider.cycle-slideshow {
	width: 100%;
}

.header-call-out {
	max-width: 700px;
	font-family: 'Open Sans', sans-serif;
	color: #273374;
	font-size: 2rem;
	line-height: 2.5rem;
	font-style: italic;
	font-weight: 400;
	text-align: center;
}

.header-call-out p {
	font-family: inherit;
	line-height: inherit;
	font-size: inherit;
	color: inherit;
	font-weight: inherit;
}

.header-call-out p:last-child {
	margin-bottom: 0px;
}

.content-wrapper.user-access {
	padding-top: 3rem;
	padding-bottom: 3rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #f1ae1b;
}

.content-wrapper.user-access b,
.content-wrapper.user-access strong {
	font-weight: 600;
}

.publications-button-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 1rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.secondary-button.prescribe-now {
	margin-top: 1rem;
}

.articles-slideshow .article-cb-content-wrap {
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-top-left-radius: 20px;
	padding: 0;
	border-bottom-right-radius: 20px;
	display: flex;
	flex-direction: column;
}

.articles-slideshow .button-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 75px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top-left-radius: 20px;
	border-bottom-right-radius: 0px;
	background-color: #fff;
}

.articles-slideshow {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	justify-content: space-between;
}

.articles-slideshow .article-source-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-left: auto;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.articles-slideshow .article-source {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 175px;
	height: 35px;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
}


.articles-slideshow .article-cb {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: auto;
	width: 100%;
	min-height: 375px;
	padding: 1.75rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	border-top: 3px none #f1af19;
	border-radius: 0px 0px 20px;
	background-color: #2280c9;
	flex-grow: 1;
}

.articles-slideshow .heading-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	margin-top: 0px;
	margin-bottom: 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.articles-slideshow .date-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0rem;
	margin-bottom: 1rem;
}

.articles-slideshow .article-date {
	margin-right: 1rem;
	margin-bottom: 0rem;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-style: italic;
	font-weight: 400;
}

.articles-slideshow .h3-heading {
	margin-top: 0px;
	margin-bottom: 0rem;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 1.35rem;
	line-height: 1.65rem;
	font-weight: 300;
}

.articles-slideshow .button-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-left: 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.articles-slideshow .read-more-button {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 0px 2rem 0px 0px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-bottom: 1px none #f1af19;
	background-color: transparent;
	background-image: url("/assets/images/articles-white-arrow.svg");
	background-position: 100% 50%;
	background-size: 15px;
	background-repeat: no-repeat;
	-webkit-transition: color 200ms ease;
	transition: color 200ms ease;
	font-family: 'Open Sans', sans-serif;
	font-size: 0.85rem;
	line-height: 1.25rem;
	font-style: italic;
}

.articles-slideshow .read-more-button:hover {
	border-bottom-color: #f1af19;
	background-image: url("/assets/images/articles-yellow-arrow.svg");
	color: #f1ae1b;
}


.articles-slideshow .article-cb._2 {
	background-color: #0d70bd;
}

.articles-slideshow .article-cb._3 {
	background-color: #005596;
}

.articles-slideshow .article-cb._4 {
	background-color: #00457a;
}

.articles-slideshow.owl-carousel .owl-item {
	display: flex;
	height: auto;
}

.articles-slideshow.owl-carousel .owl-stage {
	display: flex;
}

.content-wrapper.testimonials {
	padding-top: 3rem;
	padding-bottom: 3rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
}

.sub-title-text {
	margin-bottom: 0.75rem;
	padding-right: 1rem;
	padding-left: 1rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-radius: 10px 0px;
	background-color: rgba(13, 112, 189, 0.15);
	font-family: 'Open Sans', sans-serif;
	color: #005596;
	font-size: 0.7rem;
	font-weight: 700;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.testimonials-container {
	overflow: hidden;
	width: 70vw;
	margin-top: 2rem;
}

#testimonials_module_contentbox .author-name {
	display: block;
	width: 100%;
	padding-bottom: 0rem;
	padding-left: 0rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	font-family: 'Open Sans', sans-serif;
	color: #005596;
	font-size: 0.9rem;
	line-height: 1rem;
	font-weight: 600;
	text-align: center;
	text-transform: none;
}

#testimonials_module_contentbox .company {
	font-family: 'Open Sans', sans-serif;
	color: #333;
	font-size: 0.9rem;
	line-height: 1rem;
	text-align: center;
}

#testimonials_module_contentbox .left-arrow {
	left: 0px;
	top: 0%;
	right: auto;
	bottom: 0%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 50px;
	margin-left: 0px;
	padding-left: 0.5rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

#testimonials_module_contentbox .right-arrow {
	left: auto;
	top: 0%;
	right: 0%;
	bottom: 0%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 60px;
	margin-right: 0px;
	margin-left: 0px;
	padding-right: 0.5rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

#testimonials_module_contentbox .left-arrow .arrow.left {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 45px;
	height: 45px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-style: none;
	border-width: 2px;
	border-color: #fff;
	border-radius: 100px;
	background-color: #005596;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	background-image: url("/assets/images/white-arrow-left.svg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: 15px auto;
	transform: none;
}

#testimonials_module_contentbox .right-arrow .arrow.right {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 45px;
	height: 45px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-style: none;
	border-width: 2px;
	border-color: #fff;
	border-radius: 100px;
	background-color: #005596;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	background-image: url("/assets/images/white-arrow-right.svg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: 15px auto;
	transform: none;
}

#testimonials_module_contentbox .left-arrow .arrow.left:hover,
#testimonials_module_contentbox .right-arrow .arrow.right:hover {
	background-color: #f1af19;
}

#testimonials_module_contentbox.contentbox_item {
	overflow: hidden;
	height: auto;
	margin-left: 0px;
	margin: 2rem 0;
	background-color: transparent;
}

#testimonials_module_contentbox .w-slide-testimonial {
	padding: 2rem 3rem;
	margin-left: 5rem;
	margin-right: 5rem;
	border-radius: 20px 0px;
	background-color: rgba(51, 51, 51, 0.05);
	width: calc(100% - 10rem) !important;
}

#testimonials_module_contentbox #testimonials-content,
#testimonials_module_contentbox .testimonials-content {
	width: 100%;
	padding: 0rem 1rem;
}

#testimonials_module_contentbox #testimonials-content .description,
#testimonials_module_contentbox .testimonials-content .description {
	margin-top: 0rem;
	margin-bottom: 1.5rem;
	font-family: 'Open Sans', sans-serif;
	color: #333;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 400;
}

@media screen and (max-width: 991px) {
	.testimonial-slider {
		padding-left: 0rem;
	}

	.header-cb.call-out.left-col {
		height: 140px;
		padding-right: 2rem;
		padding-left: 2rem;
		background-color: #fff;
	}

	.header-cb.call-out.white {
		height: 140px;
		padding-right: 2rem;
		padding-left: 2rem;
		background-color: #fff;
	}

	.header-call-out-2 {
		font-size: 1.15rem;
		line-height: 1.35rem;
	}

	.video-and-slider-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.video-and-slider-inner-content {
		width: 95%;
		flex-direction: row;
	}

	.lightbox-link {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.video-and-slider-inner-content iframe,
	.lightbox-link iframe {
		height: 22vw !important;
	}

	.header-call-out {
		font-size: 1.3rem;
		line-height: 1.7rem;
	}

	.content-wrapper.user-access {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}

	.publications-button-wrapper {
		margin-top: 0rem;
	}

	.articles-slideshow {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
	}

	.articles-slideshow .article-cb {
		min-height: 265px;
	}

	.articles-slideshow .h3-heading {
		font-size: 1.4rem;
	}

	#testimonials_module_contentbox .left-arrow .arrow.left,
	#testimonials_module_contentbox .right-arrow .arrow.right {
		width: 35px;
		height: 35px;
	}

	.testimonials-container {
		width: 85vw;
	}

	#testimonials_module_contentbox.contentbox_item {
		margin: 1rem 0rem 2rem;
	}

	#testimonials_module_contentbox .w-slide-testimonial {
		margin-left: 4rem;
		margin-right: 4rem;
		width: calc(100% - 8rem) !important;
	}

	.testimonials-container {
		margin-top: 1rem;
	}
}

@media screen and (max-width: 767px) {
	.header-cb-wrapper {
		height: 40vh;
	}

	.header-cb.call-out.left-col {
		height: 150px;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.header-cb.call-out.white {
		height: 150px;
		padding: 2rem 1rem;
	}

	.header-call-out-2 {
		font-size: 0.9rem;
		line-height: 1.25rem;
	}

	.prescribers-login {
		margin-bottom: 1rem;
	}

	.patients-login {
		margin-bottom: 1rem;
	}

	.video-and-slider-inner-content {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.lightbox-link,
	.b-a-slider {
		width: 100%;
	}

	.b-a-slider {
		height: 250px;
	}

	.video-and-slider-inner-content iframe,
	.lightbox-link iframe {
		height: 100% !important;
	}

	.header-call-out {
		font-size: 1rem;
		line-height: 1.3rem;
	}

	.articles-slideshow .article-cb {
		min-height: 225px;
	}

	.testimonials-container {
		width: 90vw;
	}

	#testimonials_module_contentbox .w-slide-testimonial {
		height: auto;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.lightbox-link {
		margin-bottom: 2rem;
	}

	.video-and-slider-inner-content iframe,
	.lightbox-link iframe {
		height: 44vw !important;
	}
}

@media screen and (max-width: 479px) {
	.header-cb-wrapper {
		height: 45vh;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.header-cb.call-out.left-col {
		width: 100%;
		height: 140px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-bottom-style: none;
	}

	.header-cb.call-out.white {
		width: 100%;
		height: 140px;
		padding-top: 1rem;
		padding-bottom: 1rem;
		border-top-right-radius: 0px;
	}

	.header-call-out-2 {
		font-size: 1rem;
		line-height: 1.25rem;
	}

	.prescribers-login {
		margin-right: 0rem;
		margin-bottom: 0.5rem;
	}

	.patients-login {
		margin-bottom: 0.5rem;
	}

	.header-call-out {
		font-size: 1rem;
		line-height: 1.25rem;
	}

	.content-wrapper.user-access {
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}

	.articles-slideshow .article-cb {
		min-height: 225px;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.articles-slideshow .h3-heading {
		font-size: 1.15rem;
		line-height: 1.3rem;
	}

	.content-wrapper.testimonials {
		padding-top: 2.5rem;
		padding-bottom: 1.5rem;
	}

	#testimonials_module_contentbox .author-name {
		padding-bottom: 0rem;
		padding-left: 0rem;
		text-align: center;
	}

	#testimonials_module_contentbox .left-arrow,
	#testimonials_module_contentbox .right-arrow {
		display: none;
	}

	.testimonials-container {
		width: 100vw;
	}

	#testimonials_module_contentbox.contentbox_item {
		margin-bottom: 1rem;
	}

	#testimonials_module_contentbox .w-slide-testimonial {
		margin-left: 2rem;
		margin-right: 2rem;
		height: auto;
		padding-right: 0.25rem;
		padding-left: 0.25rem;
		width: calc(100% - 4rem) !important;
	}

	#testimonials_module_contentbox #testimonials-content .description,
	#testimonials_module_contentbox .testimonials-content .description {
		font-size: 0.9rem;
		line-height: 1.25rem;
		text-align: center;
	}

	.b-a-slider {
		height: 130px;
	}

	.lightbox-link {
		margin-bottom: 2rem;
	}

	.hero-img {
		height: 45vh;
	}
}

/* left navigation mobile menu */
.mini-menu-dropdown {
	margin-bottom: 20px;
}

.mini-menu-dropdown,
.mini-menu-dropdown .mini-menu-dropdown-list {
	display: none;
}

.mini-menu-dropdown .mini-menu-dropdown-toggle:after {
	content: '';
	width: 15px;
	height: 17px;
	background-image: url('/assets/images/arrow-medium-up-white.svg');
	background-size: 15px;
	display: block;
	transform: rotate(180deg);
	background-repeat: no-repeat;
}

.mini-menu-dropdown .mini-menu-dropdown-toggle {
	margin-top: 0px;
	margin-bottom: 0px;
	padding: 15px 20px;
	background-color: #005596;
	color: #fff;
	font-size: 1.1em;
	line-height: 1.1em;
	letter-spacing: 1px;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	border-radius: 20px;
	transition: all 100ms ease 0s;
}

.mini-menu-dropdown.open .mini-menu-dropdown-toggle {
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.mini-menu-dropdown .section-heading-wrapper {
	display: none;
}

.mini-menu-dropdown .left-nav-list-link {
	background-position: 20px center;
	padding: 0.75rem 1.5rem 0.75rem 2rem;
}

.mini-menu-dropdown .left-nav-list-link:hover {
	padding: 0.75rem 1.5rem 0.75rem 2rem;
	transform: none;
	margin-left: 10px;
}

.mini-menu-dropdown .left-nav-list-link.w--current {
	padding: 0.75rem 1.5rem 0.75rem 2rem;
	background-color: rgba(13, 112, 189, 0.05);
}

.mini-menu-dropdown .left-nav-nested-list-link.grandchild,
.mini-menu-dropdown .left-nav-nested-list-link.grandchild.w--current {
	background-position: 15px center;
}

.mini-menu-dropdown .left-nav-nested-list-link.great-grandchild,
.mini-menu-dropdown .left-nav-nested-list-link.great-grandchild.current {
	background-position: 20px center;
}

@media (max-width: 991px) {
	.mini-menu-dropdown {
		display: block;
	}
}

/* grid styles */
.grid * {
	box-sizing: border-box;
}

.grid {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: -1%;
	margin-bottom: 20px;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	box-sizing: border-box;
}

.grid-box {
	margin-right: 1%;
	margin-bottom: 15px;
	padding: 20px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.grid-box.half {
	width: 49%;
}

.grid-box.third {
	width: 32.33%;
}

.grid-box.quarter {
	width: 24%;
}

.grid-box.fifth {
	width: 19%;
}

.grid-box.twothirds {
	width: 65.66%;
}

.grid-box.center {
	text-align: center;
}

.grid-box.center p {
	margin: 0 auto;
	max-width: 400px;
}

@media (max-width: 991px) {
	.grid-box.quarter {
		width: 49%;
	}

	.grid-box.fifth {
		width: 49%;
	}
}

@media (max-width: 767px) {
	.grid-box.half {
		width: 100%;
	}

	.grid {
		margin-left: -20px;
		margin-right: calc(-20px + 1%);
	}

	.grid-box.twothirds {
		width: 100%;
	}

	.grid-box.third {
		width: 100%;
	}

	.grid-box-1 {
		order: 2;
	}

	.grid-box-2 {
		order: 1;
	}
}

@media (max-width: 630px) {
	.grid-box.third {
		order: -1;
	}
}

@media (max-width: 479px) {
	.grid-box {
		margin-right: 0%;
	}

	.grid-box.half {
		width: 100%;
	}

	.grid-box.third {
		width: 100%;
	}

	.grid-box.quarter {
		width: 100%;
	}

	.grid-box.fifth {
		width: 100%;
	}
}
/*Locations */
div#locations_module.list .image {
	width: auto;
}

div.box.right-sidebar-box.filter-search .title {
	margin-bottom: 1.2rem;
}

#locations_module.list .metadata2.address {
	padding-bottom: 10px;
}

#locations_module_search_contentbox.contentbox_item .item_field input#PostalCode {
	display: inline-block !important;
	width: calc(50% - 10px);
}

#locations_module_search_contentbox.contentbox_item .item_field select#MilesRadius {
	width: 46%;
	margin-right: 10px;
	display: inline-block !important;
}


/**************************** Patient Homepage **********************************/
/********************************************************************************/
.patient-home .cbtext {
	display: block;
	width: 100%;
}

.patient-home .herowrap {
	grid-row-gap: 48px;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	padding: 48px 64px 128px;
	text-decoration: none;
	display: flex;
}

.patient-home .pagetitle {
	grid-row-gap: 10px;
	background-color: #fff6e9;
	background-image: none;
	border-top-left-radius: 25px;
	border-bottom-right-radius: 25px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 500px;
	padding-top: 8px;
	padding-bottom: 8px;
	text-decoration: none;
	display: flex;
}

.patient-home .title {
	color: #273474;
	margin-top: 0;
	margin-bottom: 0;
	font-family: Afacad, sans-serif;
	font-size: 32px;
	font-weight: 700;
	line-height: 122.77%;
	text-decoration: none;
}

.patient-home .title.white {
	color: #273474;
	font-size: 20px;
}

.patient-home .herocontentwrap {
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	max-width: 1250px;
	margin-left: auto;
	margin-right: auto;
	text-decoration: none;
	display: flex;
}

.patient-home .herolightbox {
	grid-column-gap: 485px;
	flex: 0 auto;
	justify-content: center;
	align-items: flex-end;
	width: 60%;
	height: 500px;
	padding: 32px 45px;
	text-decoration: none;
	display: flex;
	position: relative;
}

.patient-home .popupinstruction {
	z-index: 1;
	cursor: pointer;
	background-color: #fff;
	border-radius: 20px 0;
	justify-content: center;
	align-items: center;
	width: auto;
	max-width: 500px;
	height: auto;
	padding: 3rem;
	font-family: Afacad, sans-serif;
	transition: all 0.4s;
	display: flex;
	z-index: 1000;
	position: absolute;
	top: 20%;
	box-shadow: 0 2px 11px -4px #0003;
}

.patient-home .popupinstruction.safety-popup {
	display: none;
	right: auto;
	left: auto;
}

.patient-home .text {
	background-color: #0000;
	border-radius: 0;
	width: 100%;
	height: auto;
	padding: 0 1rem;
	display: inline-block;
	position: relative;
}

.patient-home .attribution {
	color: #273474;
	text-align: center;
	text-transform: none;
	justify-content: center;
	align-self: flex-start;
	align-items: center;
	width: 100%;
	margin-bottom: 16px;
	padding-bottom: 0;
	padding-left: 0;
	font-family: Afacad, sans-serif;
	font-size: 1.8rem;
	font-weight: 700;
	line-height: 1.7rem;
	display: block;
}

.patient-home .paragraph {
	color: #333;
	margin-top: 0;
	margin-bottom: 1.5rem;
	font-family: Open Sans, sans-serif;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5rem;
	max-height: 300px;
	overflow-x: auto;
}

.patient-home .closeicon {
	z-index: 1;
	cursor: pointer;
	width: 28px;
	height: 28px;
	text-decoration: none;
	position: absolute;
	inset: -16px -16px auto auto;
	transform: rotate(45deg);
}

.patient-home .closeimg {
	object-fit: cover;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	display: flex;
}

.patient-home .heroimage {
	grid-row-gap: 10px;
	background-color: #e9f7fc;
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 70%;
	border-top-left-radius: 50px;
	border-bottom-right-radius: 25px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 500px;
	padding: 108px 86px;
	text-decoration: none;
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
}

.patient-home .heroimage.img2,
.patient-home .heroimage.img1 {
	background-color: #f5f5f5;
}

.patient-home .imagethubnailwrapper {
	grid-row-gap: 12px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 81px;
	margin-right: auto;
	text-decoration: none;
	display: flex;
	position: relative;
}

.patient-home .imagethumbnail {
	grid-column-gap: 10px;
	border: 1px solid #f1ae1b;
	object-fit: contain;
	background-color: #fff;
	border-radius: 10px;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 60px;
	padding: 5px;
	display: flex;
}

.patient-home .nucaraproductimage_1-1 {
	object-fit: contain;
}

.patient-home .vectors-wrapper-2 {
	object-fit: contain;
	justify-content: center;
	align-items: center;
	width: 81px;
	height: 48px;
	text-decoration: none;
	display: flex;
}

.patient-home .instructionslink {
	z-index: 1;
	grid-column-gap: 10px;
	background-image: linear-gradient(to bottom, #f1ae1b, #f1ae1b);
	color: #273474;
	cursor: pointer;
	border: 5px #fff;
	border-radius: 15px 0;
	justify-content: center;
	align-items: center;
	margin-left: auto;
	padding: 8px 16px;
	font-family: Afacad, sans-serif;
	text-decoration: none;
	transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	display: flex;
	position: absolute;
	inset: auto 0 0 auto;
}

.patient-home .link-container {
	white-space: nowrap;
	font-family: Afacad, sans-serif;
	font-size: 16px;
	font-weight: 400;
	text-decoration: none;
}

.patient-home .herocontentwrapper {
	grid-row-gap: 48px;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 40%;
	max-width: 550px;
	padding: 32px 64px;
	text-decoration: none;
	display: flex;
}

.patient-home .herocontent {
	grid-row-gap: 16px;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	max-width: 317px;
	text-decoration: none;
	display: flex;
}

.patient-home .title-container {
	color: #273474;
	margin-top: 0;
	margin-bottom: 0;
	font-family: Afacad, sans-serif;
	font-size: 32px;
	font-weight: 700;
	line-height: 122.77%;
	text-decoration: none;
}

.patient-home .text-2 {
	color: #273474;
	margin-top: 0;
	margin-bottom: 0;
	font-family: Afacad, sans-serif;
	font-size: 24px;
	font-weight: 400;
	line-height: 122.77%;
	text-decoration: none;
}

.patient-home .mainbutton {
	background-color: #f1ae1b;
	box-shadow: none;
	color: #273474;
	text-transform: capitalize;
	border-radius: 15px 0;
	justify-content: center;
	align-items: center;
	width: 225px;
	margin-bottom: 0;
	padding: 1rem 2rem;
	font-family: Afacad, sans-serif;
	font-size: 1.1rem;
	font-weight: 400;
	transition: all 0.8s;
	display: flex;
	position: static;
}

.patient-home .mainbutton:hover {
	background-color: #025596;
	color: #fff;
	background-image: none;
	border-radius: 0 15px;
}

.patient-home .instructionslink:hover {
	background-image: linear-gradient(to bottom, #273474, #273474);
	color: #fff;
}

.patient-home .accordionwrapper {
	width: 100%;
	min-height: 200px;
}

.patient-home .accordion-item {
	border-bottom: 1px solid #f1ae1b;
	padding-bottom: 8px;
	padding-left: 16px;
	padding-right: 16px;
	width: 100%;
}

.patient-home .accordion-item.alt {
	border-bottom-color: #f1ae1b;
	width: 100%;
	padding-left: 0;
	padding-right: 0;
}

.accordionwrapper .accordion-item.alt:first-child {
	border-top: 1px solid #f1ae1b;
}

.patient-home .accordion-item-trigger {
	cursor: pointer;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding-top: 16px;
	padding-bottom: 0;
	display: flex;
}

.patient-home .text-3 {
	color: #273474;
	margin-top: 0;
	margin-bottom: 0;
	font-family: Afacad, sans-serif;
	font-size: 23px;
	font-weight: 500;
	line-height: 122.77%;
	text-decoration: none;
	text-transform: none;
	padding-right: 15px;
}

.patient-home .icon.accordion-icon {
	cursor: pointer;
	background-image: url('/assets/images/faqArrow.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: contain;
	min-width: 16px;
	min-height: 16px;
	margin-right: 20px;
}

.patient-home .accordion-item-content {
	grid-column-gap: 8px;
	grid-row-gap: 8px;
	flex-flow: column;
	margin-top: 8px;
	display: flex;
	overflow: hidden;
}

.patient-home .loremtext {
	color: #273474;
	margin-top: 0;
	margin-bottom: 0;
	font-family: Afacad, sans-serif;
	font-size: 20px;
	font-weight: 400;
	line-height: 122.77%;
	text-decoration: none;
}

.patient-home .cardssection {
	grid-row-gap: 65px;
	background-image: url('/assets/images/HowItWorksSection-wartpeel.webp');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	min-height: 825px;
	padding: 128px 64px;
	text-decoration: none;
	display: flex;
}

.patient-home .titles-section {
	color: #000;
	text-align: left;
	margin-top: 0;
	margin-bottom: 0;
	font-family: Afacad, sans-serif;
	font-size: 44px;
	font-weight: 400;
	line-height: 110%;
	text-decoration: none;
}

.patient-home .stepscardswrap {
	grid-column-gap: 0px;
	flex: 0 auto;
	justify-content: center;
	align-self: center;
	align-items: center;
	width: 100%;
	max-width: 1200px;
	height: 450px;
	text-decoration: none;
	display: flex;
}

.patient-home .titles-section.mobile {
	display: none;
}

.patient-home .cardwrap {
	grid-column-gap: 10px;
	justify-content: flex-end;
	align-items: stretch;
	width: 30%;
	height: 100%;
	padding: 32px;
	text-decoration: none;
	display: flex;
	position: relative;
}

.patient-home .d-31-card {
	cursor: pointer;
	background-color: #0000;
	border-radius: 10px;
	width: 100%;
	max-width: 300px;
	padding: 0;
	position: relative;
	inset: 0;
}

.patient-home .card-front {
	z-index: 1;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	background-color: #fff;
	border: 10px solid #fff6e9;
	border-top-right-radius: 25px;
	border-bottom-left-radius: 25px;
	flex-flow: column;
	justify-content: flex-start;
	align-items: center;
	max-width: 332px;
	padding: 28px 20px;
	text-decoration: none;
	display: flex;
	position: absolute;
	inset: 0%;
	box-shadow: 0 2px 5px #0003;
}

.patient-home .stepcardcontents {
	grid-column-gap: 8px;
	grid-row-gap: 8px;
	flex-flow: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	max-width: 272px;
	height: 100%;
	text-decoration: none;
	display: flex;
}

.patient-home .text-6 {
	color: #273474;
	margin-top: 0;
	margin-bottom: 0;
	font-family: Afacad, sans-serif;
	font-size: 24px;
	font-weight: 700;
	line-height: 100%;
	text-decoration: none;
}

.patient-home .rectangle-6 {
	background-color: #f7f7f7;
	border-radius: 25px;
	height: 100%;
	margin-top: 15px;
	margin-bottom: 10px;
}

.patient-home .vectors-wrapper-11 {
	object-fit: cover;
	justify-content: center;
	align-items: center;
	width: 25px;
	height: 25px;
	margin-right: -25px;
	text-decoration: none;
	display: flex;
	position: absolute;
	inset: 50% 0% auto auto;
	transform: rotate(30deg);
}

.patient-home .flipicon {
	grid-column-gap: 10px;
	background-color: #273474;
	border-radius: 100px;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	padding: 9px;
	text-decoration: none;
	display: flex;
	position: absolute;
	inset: auto 7px 30px auto;
}

.patient-home .vectors-wrapper-12 {
	object-fit: cover;
	justify-content: center;
	align-items: center;
	width: 12px;
	height: 12px;
	text-decoration: none;
	display: flex;
}

.patient-home .card-back {
	z-index: 0;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	background-color: #273474;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	transform-style: preserve-3d;
	border-top-right-radius: 25px;
	border-bottom-left-radius: 25px;
	flex-flow: column;
	justify-content: flex-start;
	align-items: center;
	max-width: 332px;
	padding: 32px;
	text-decoration: none;
	display: flex;
	position: absolute;
	inset: 0%;
	transform: rotateX(0) rotateY(180deg) rotateZ(0);
	box-shadow: 0 2px 5px #0003;
}

.patient-home .stepcardcontents {
	grid-column-gap: 8px;
	grid-row-gap: 8px;
	flex-flow: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	max-width: 272px;
	height: 100%;
	text-decoration: none;
	display: flex;
}

.patient-home .text-6 {
	color: #273474;
	margin-top: 0;
	margin-bottom: 0;
	font-family: Afacad, sans-serif;
	font-size: 24px;
	font-weight: 700;
	line-height: 100%;
	text-decoration: none;
}

.patient-home .text-6.white {
	color: #fff;
}

.patient-home .loremtext.white {
	color: #fff;
	font-size: 18px;
}

.patient-home .flipicon {
	grid-column-gap: 10px;
	background-color: #273474;
	border-radius: 100px;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	padding: 9px;
	text-decoration: none;
	display: flex;
	position: absolute;
	inset: auto 7px 30px auto;
}

.patient-home .flipicon.white {
	background-image: linear-gradient(#fff, #fff);
	width: 30px;
	height: 30px;
	padding: 8px;
}

.patient-home .vectors-wrapper-12 {
	object-fit: cover;
	justify-content: center;
	align-items: center;
	width: 12px;
	height: 12px;
	text-decoration: none;
	display: flex;
}

.patient-home .hairlosssection {
	justify-content: center;
	align-items: center;
	width: 100%;
	min-height: 30vw;
	padding-top: 128px;
	padding-bottom: 128px;
	text-decoration: none;
	display: flex;
}

.patient-home .contentwrapper {
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 1150px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 32px;
	display: flex;
}

.patient-home .contentwrap {
	grid-row-gap: 32px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 50%;
	max-width: 450px;
	margin-right: auto;
	text-decoration: none;
	display: flex;
}

.patient-home .text-7-copy {
	color: #273474;
	text-align: left;
	margin-top: 0;
	margin-bottom: 0;
	font-family: Afacad, sans-serif;
	font-size: 43px;
	font-weight: 400;
	line-height: 110%;
	text-decoration: none;
}

.patient-home .image-container {
	object-fit: cover;
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: contain;
	width: 100%;
	max-width: none;
	height: 65vh;
	min-height: 500px;
	margin-top: -128px;
	margin-right: -75px;
}

.patient-home .faqsection {
	flex-flow: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding-top: 0;
	padding-bottom: 128px;
	text-decoration: none;
	display: flex;
}

.patient-home .faqbox {
	grid-row-gap: 32px;
	background-color: #f5f5f5;
	border-radius: 25px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 1150px;
	padding: 64px;
	text-decoration: none;
	display: flex;
	box-shadow: 4px 4px 13px #00000021;
}

.patient-home .text-9 {
	color: #000;
	text-align: center;
	margin-top: 0;
	margin-bottom: 0;
	font-family: Afacad, sans-serif;
	font-size: 48px;
	font-weight: 400;
	line-height: 115%;
	text-decoration: none;
}

.patient-home .faqcontent {
	grid-column-gap: 418px;
	flex-wrap: wrap;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	max-width: 800px;
	text-decoration: none;
	display: flex;
	margin: 0 auto;
}

.patient-home .accordion-item {
	border-bottom: 1px solid #f1ae1b;
	padding-bottom: 8px;
	padding-left: 16px;
	padding-right: 16px;
}

.patient-home .accordion-item-trigger {
	cursor: pointer;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding-top: 16px;
	padding-bottom: 0;
	display: flex;
}

.patient-home .icon.accordion-icon {
	cursor: pointer;
	background-image: url('/assets/images/faqArrow.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: contain;
	width: 16px;
	height: 16px;
	margin-right: 20px;
}

.patient-home .accordion-item-content {
	grid-column-gap: 8px;
	grid-row-gap: 8px;
	flex-flow: column;
	margin-top: 8px;
	display: flex;
	overflow: hidden;
}

.patient-home .answer {
	color: #273474;
	margin-bottom: 0;
	font-family: Afacad, sans-serif;
	font-size: 22px;
	font-weight: 400;
	line-height: 122.77%;
	text-decoration: none;
}

.patient-home .iconcontentboxes {
	grid-row-gap: 40px;
	background-color: #fff;
	border-radius: 25px;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	max-width: 526px;
	height: 551px;
	padding: 48px;
	text-decoration: none;
	display: flex;
}

.patient-home .infosection {
	grid-column-gap: 48px;
	background-image: url('/assets/images/AdobeStock_968693828.webp');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding: 128px 64px;
	text-decoration: none;
	display: flex;
	position: relative;
}

.patient-home .prescriberLocation {
	grid-row-gap: 16px;
	background-color: #fff;
	border-radius: 25px;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	height: 551px;
	padding: 32px;
	text-decoration: none;
	display: flex;
	overflow: auto;
	min-width: 30%;
	max-width: 470px;
}

.patient-home .row {
	grid-column-gap: 48px;
	cursor: pointer;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	text-decoration: none;
	display: flex;
	position: relative;
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

.patient-home .iconbox {
	z-index: 1;
	grid-column-gap: 10px;
	background-color: #f5f5f5;
	border-radius: 15px;
	justify-content: center;
	align-items: center;
	width: 125px;
	height: 125px;
	padding: 33px;
	text-decoration: none;
	display: flex;
	position: relative;
}

.patient-home .vectors-wrapper-17 {
	object-fit: fill;
	justify-content: center;
	align-items: center;
	max-width: 60px;
	text-decoration: none;
	display: block;
}

.patient-home .text-15 {
	z-index: 1;
	color: #273474;
	margin-top: 0;
	margin-bottom: 0;
	font-family: Afacad, sans-serif;
	font-size: 22px;
	font-weight: 400;
	line-height: 122.77%;
	text-decoration: none;
	position: relative;
}

.patient-home .iconboxfill {
	z-index: 0;
	grid-column-gap: 10px;
	background-color: #f5f5f5;
	border-radius: 15px;
	justify-content: center;
	align-items: center;
	width: 125px;
	height: 125px;
	padding: 30px 26px;
	text-decoration: none;
	display: flex;
	position: absolute;
}

.patient-home .cbtext p {
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	font-family: inherit;
	font-weight: inherit;
}

.patient-home .cbtext.loremtext p,
.patient-home .cbtext p:last-child {
	margin-bottom: 0px;
}

.patient-home .text-12 {
	color: #1c274c;
	text-align: left;
	margin-top: 0;
	margin-bottom: 0;
	font-family: Afacad, sans-serif;
	font-size: 20px;
	font-weight: 400;
	line-height: 122.77%;
	text-decoration: none;
}

.patient-home .text-11 {
	color: #000;
	text-align: center;
	margin-top: 0;
	margin-bottom: 0;
	font-family: Afacad, sans-serif;
	font-size: 28px;
	font-weight: 700;
	line-height: 122.77%;
	text-decoration: none;
}

.patient-home .testimonials {
	grid-column-gap: 32px;
	grid-row-gap: 32px;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-height: 30vw;
	padding-top: 0;
	padding-bottom: 90px;
	text-decoration: none;
	display: flex;
}

.patient-home .titles {
	color: #000;
	text-align: left;
	margin-top: 0;
	margin-bottom: 0;
	font-family: Afacad, sans-serif;
	font-size: 44px;
	font-weight: 400;
	line-height: 110%;
	text-decoration: none;
}

.patient-home .slider {
	background-color: #ddd0;
	width: 100%;
	max-width: 1200px;
	overflow: auto;
	margin: 0 auto;
}

.patient-home .slider .list {
	padding-left: 48px;
	padding-right: 48px;
}

.patient-home .icon-2,
.patient-home .icon-3 {
	color: #1c274c;
	font-size: 25px;
	display: block;
}

.patient-home .w-icon-slider-left:before {
	content: "\e601";
	position: relative;
	top: -17px;
}

.patient-home .w-icon-slider-right:before {
	content: "\e600";
	position: relative;
	top: -17px;
}

.patient-home .slick-dots {
	display: none !important;
}

.patient-home #testimonials.homepage_contentbox .slick-track {
	display: flex;
}

.patient-home .testimonialwrap-outer {
	padding-bottom: 50px;
	margin-left: 10px;
	margin-right: 10px;
	height: auto;
}

.patient-home .testimonialwrap {
	background-color: #e1f3fb;
	border-radius: 25px;
	flex-flow: column;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	padding: 32px;
	display: flex;
	position: relative;
	min-height: 245px;
}

.patient-home .answer {
	color: #303030;
	margin-bottom: 0;
	font-family: Afacad, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 122.77%;
	text-align: left;
	text-decoration: none;
}

.patient-home .name {
	color: #000;
	margin-bottom: 0;
	font-family: Afacad, sans-serif;
	font-size: 16px;
	font-weight: 700;
	line-height: 122.77%;
	text-align: left;
	text-decoration: none;
}

.patient-home .triangledecor {
	background-image: url('/assets/images/triangle_decor.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: contain;
	width: 50px;
	height: 50px;
	margin-left: auto;
	margin-right: auto;
	position: absolute;
	inset: auto 0 -25px;
}

@media screen and (max-width: 991px) {
	.patient-home .herowrap {
		padding-bottom: 40px;
		padding-left: 32px;
		padding-right: 32px;
	}

	.patient-home .pagetitle {
		width: 50%;
		padding-left: 36px;
		padding-right: 36px;
	}

	.patient-home .herocontentwrap {
		flex-flow: row;
		justify-content: center;
		align-items: stretch;
		max-width: none;
	}

	.patient-home .herolightbox {
		aspect-ratio: 1;
		width: 50%;
		height: 100%;
	}

	.patient-home .popupinstruction {
		width: 90%;
		padding: 1rem;
	}

	.patient-home .attribution {
		font-size: 1.5rem;
	}

	.patient-home .paragraph {
		text-align: left;
	}

	.patient-home .closeicon {
		top: -5px;
		right: -5px;
	}

	.patient-home .closeicon.safety-close {
		top: 0;
	}

	.patient-home .heroimage {
		border-top-left-radius: 25px;
		height: 100%;
		padding-top: 60px;
		padding-bottom: 60px;
	}

	.patient-home .herocontentwrapper {
		grid-column-gap: 48px;
		grid-row-gap: 48px;
		flex-flow: column;
		width: 50%;
		max-width: none;
		padding-top: 24px;
		padding-left: 32px;
		padding-right: 0;
	}

	.patient-home .herocontent {
		grid-row-gap: 8px;
	}

	.patient-home .text-2 {
		font-size: 20px;
	}

	.patient-home .accordionwrapper {
		height: auto;
	}

	.patient-home .cardssection {
		grid-row-gap: 0px;
		justify-content: flex-start;
		align-items: flex-start;
		padding-top: 60px;
		padding-bottom: 60px;
	}

	.patient-home .titles-section {
		text-align: left;
		width: 50%;
	}

	.patient-home .titles.alt,
	.patient-home .titles-section.alt {
		display: none;
	}

	.patient-home .stepscardswrap {
		flex-flow: column;
		justify-content: flex-start;
		align-items: flex-start;
		width: 100%;
		max-width: 650px;
		height: auto;
	}

	.patient-home .titles-section.mobile {
		display: block;
	}

	.patient-home .cardwrap {
		grid-column-gap: 0px;
		width: 100%;
		height: 400px;
		padding-left: 0;
		padding-right: 0;
	}

	.patient-home .d-31-card {
		aspect-ratio: 1;
		width: 100%;
		min-width: auto;
		max-width: none;
	}

	.patient-home .card-front {
		justify-content: flex-start;
		align-items: flex-start;
		max-width: none;
		padding: 24px;
		transform: rotate(0);
	}

	.patient-home .stepcardcontents {
		max-width: none;
		height: 100%;
	}

	.patient-home .rectangle-6 {
		width: 100%;
		overflow: hidden;
		margin-top: 0px;
		margin-bottom: 0px;
	}

	.patient-home .vectors-wrapper-11 {
		display: none;
	}

	.patient-home .flipicon {
		bottom: 16px;
		right: 16px;
	}

	.patient-home .card-back {
		max-width: none;
		padding: 64px;
	}

	.patient-home .stepcardcontents {
		max-width: none;
		height: 100%;
	}

	.patient-home .loremtext.white {
		font-size: 18px;
	}

	.patient-home .flipicon {
		bottom: 16px;
		right: 16px;
	}

	.patient-home .hairlosssection {
		flex-flow: column;
		padding: 0 32px 60px;
	}

	.patient-home .contentwrapper {
		grid-column-gap: 32px;
		grid-row-gap: 32px;
		flex-flow: column;
		padding-right: 32px;
	}

	.patient-home .contentwrap {
		grid-row-gap: 16px;
		flex-flow: column;
		justify-content: flex-start;
		align-items: flex-start;
		width: 100%;
		max-width: none;
	}

	.patient-home .text-7-copy {
		font-size: 32px;
	}

	.patient-home .image-container {
		order: -1;
		height: 50vw;
		min-height: auto;
		margin-top: 0;
		margin-left: 0;
		margin-right: 0;
	}

	.patient-home .faqsection {
		padding-bottom: 60px;
		padding-left: 32px;
		padding-right: 32px;
	}

	.patient-home .faqbox {
		padding: 32px;
	}

	.patient-home .answer {
		font-size: 20px;
	}

	.patient-home .iconcontentboxes {
		grid-row-gap: 16px;
		justify-content: flex-start;
		align-items: flex-start;
		width: auto;
		max-width: none;
		height: 478px;
		padding: 24px;
	}

	.patient-home .infosection {
		grid-column-gap: 24px;
		flex-flow: row;
		justify-content: center;
		align-items: flex-start;
		padding: 60px 32px;
	}

	.patient-home .prescriberLocation {
		justify-content: flex-start;
		align-items: stretch;
		width: 100%;
		max-width: 600px;
		height: 478px;
		overflow: visible;
	}

	.patient-home .row {
		grid-column-gap: 16px;
		flex-flow: column;
		justify-content: flex-start;
		align-items: flex-start;
		padding: 16px;
	}

	.patient-home .iconbox {
		width: 48px;
		height: 64px;
		padding: 0 10px;
	}

	.patient-home .vectors-wrapper-17 {
		max-width: 40px;
		max-height: 50px;
	}

	.patient-home .text-15 {
		font-size: 16px;
		line-height: 115%;
	}

	.patient-home .iconboxfill {
		grid-column-gap: 0px;
		flex-flow: column;
		width: 100%;
		height: 100%;
		inset: 0%;
	}

	.patient-home .testimonials {
		flex-flow: column;
		padding: 60px 32px 64px;
	}

	.patient-home .titles {
		text-align: left;
		width: 50%;
	}

	.patient-home .titles.alt {
		display: none;
	}

	.patient-home .testimonialwrap {
		padding: 24px;
	}

	.patient-home .name {
		font-size: 20px;
	}
}

@media screen and (max-width: 767px) {
	.patient-home .herocontentwrap {
		flex-flow: column;
	}

	.patient-home .herolightbox {
		width: 100%;
	}

	.patient-home .popupinstruction {
		width: 100%;
		max-width: 90%;
		height: auto;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}

	.patient-home .attribution {
		padding-left: 0;
	}

	.patient-home .closeicon {
		top: -24px;
		right: 0;
	}

	.patient-home .herocontentwrapper {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		width: 100%;
	}

	.patient-home .mainbutton {
		margin-bottom: 1rem;
	}

	.patient-home .accordion-item-content {
		margin-right: 0;
	}

	.patient-home .loremtext {
		width: 100%;
	}

	.patient-home .cardssection {
		grid-column-gap: 32px;
		grid-row-gap: 32px;
		padding-top: 60px;
		padding-bottom: 60px;
	}

	.patient-home .titles-section {
		text-align: left;
		width: 100%;
		font-size: 48px;
	}

	.patient-home .stepscardswrap {
		flex-flow: column;
		justify-content: flex-start;
		align-items: flex-start;
		height: auto;
	}

	.patient-home .titles-section.mobile {
		margin-bottom: 16px;
	}

	.patient-home .cardwrap {
		width: 100%;
		padding-top: 0;
		padding-right: 0;
	}

	.patient-home .d-31-card {
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;
	}

	.patient-home .card-front {
		max-width: none;
	}

	.patient-home .vectors-wrapper-11 {
		display: none;
	}

	.patient-home .card-back {
		justify-content: center;
		align-items: center;
		max-width: none;
		padding-top: 48px;
	}

	.patient-home .contentwrap {
		grid-row-gap: 16px;
		flex-flow: column;
	}

	.patient-home .text-7-copy {
		width: 100%;
		font-size: 32px;
	}

	.patient-home .faqbox {
		padding: 48px 32px;
	}

	.patient-home .accordion-item-content {
		margin-right: 0;
	}

	.patient-home .iconcontentboxes {
		height: auto;
	}

	.patient-home .infosection {
		grid-row-gap: 32px;
		flex-flow: column;
		justify-content: center;
		align-items: stretch;
	}

	.patient-home .row {
		flex-flow: row;
		justify-content: center;
		align-items: center;
	}

	.patient-home .iconbox {
		width: 80px;
	}

	.patient-home .pagetitle {
		width: 100%;
		max-width: 100%;
	}

	.patient-home .text-11 {
		text-align: left;
	}

	.patient-home .prescriberLocation {
		max-width: unset;
	}

	.patient-home .titles {
		text-align: left;
		width: 100%;
		font-size: 48px;
	}

	.patient-home .slider .list {
		margin-bottom: 0;
	}

	.patient-home .answer {
		font-size: 16px;
	}

	.patient-home .popupinstruction.safety-popup {
		top: auto;
		bottom: 15%;
		right: auto;
		max-width: calc(100% - 70px);
		left: auto;
		margin-left: 10px;
		margin-right: 10px;
	}
}

@media screen and (max-width: 479px) {
	.patient-home .herowrap {
		grid-row-gap: 24px;
		padding-bottom: 0;
		padding-left: 16px;
		padding-right: 16px;
	}

	.patient-home .herolightbox {
		grid-column-gap: 0px;
		grid-row-gap: 0px;
		justify-content: flex-start;
		align-items: flex-start;
		height: 380px;
		padding: 24px;
	}

	.patient-home .popupinstruction {
		z-index: 2;
		width: auto;
		max-width: none;
		height: auto;
		margin-right: 24px;
		padding-left: 0.25rem;
		padding-right: 0.25rem;
		top: 5%;
	}

	.patient-home .popupinstruction.safety-popup {
		width: calc(100% - 20px);
		margin-right: 10px;
		margin-left: 10px;
	}

	.patient-home .attribution {
		text-align: center;
		padding-bottom: 0;
		padding-left: 0;
	}

	.patient-home .paragraph {
		text-align: center;
		font-size: 0.9rem;
		line-height: 1.25rem;
	}

	.patient-home .closeicon {
		top: -10px;
	}

	.patient-home .heroimage {
		height: 380px;
		padding-top: 60px;
		padding-bottom: 60px;
	}

	.patient-home .herocontentwrapper {
		padding-bottom: 24px;
		padding-left: 24px;
		padding-right: 24px;
	}

	.patient-home .accordion-item {
		padding-left: 0;
		padding-right: 0;
	}

	.patient-home .accordion-item-trigger {
		padding-right: 16px;
	}

	.patient-home .text-3 {
		font-size: 20px;
		text-align: left;
	}

	.patient-home .cardssection {
		grid-column-gap: 32px;
		grid-row-gap: 32px;
		background-repeat: repeat-y;
		background-size: cover;
		padding: 60px 24px;
	}

	.patient-home .card-front {
		padding: 16px;
	}

	.patient-home .stepcardcontents {
		max-width: none;
		height: 100%;
	}

	.patient-home .rectangle-6 {
		height: 86.2063%;
		overflow: hidden;
		max-height: 100%;
		max-width: 100%;
	}

	.patient-home .stepcardcontents {
		max-width: none;
		height: 100%;
	}

	.patient-home .contentwrapper {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		padding-left: 0;
		padding-right: 0;
	}

	.patient-home .image-container {
		background-position: 50% 100%;
		background-size: contain;
		width: 150%;
		height: 100vw;
	}

	.patient-home .faqsection {
		padding-left: 16px;
		padding-right: 16px;
	}

	.patient-home .faqbox {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		padding: 24px;
	}

	.patient-home .accordion-item {
		padding-left: 0;
		padding-right: 0;
	}

	.patient-home .accordion-item-trigger {
		padding-right: 16px;
	}

	.patient-home .answer {
		font-size: 18px;
	}

	.patient-home .iconcontentboxes {
		grid-row-gap: 16px;
		justify-content: flex-start;
		align-items: center;
		height: auto;
		padding: 16px;
	}

	.patient-home .prescriberLocation {
		min-height: 400px;
		padding: 24px 24px 16px;
		height: auto;
		overflow: visible;
	}

	.patient-home .iconboxfill {
		grid-column-gap: 0px;
		width: 100%;
	}

	.patient-home .cbtext p {
		text-align: left;
	}

	.patient-home .testimonials,
	.patient-home .slider .list {
		padding-left: 16px;
		padding-right: 16px;
	}

	.patient-home .w-icon-slider-left:before {
		left: -30px;
	}

	.patient-home .w-icon-slider-right:before {
		right: -30px;
	}

	.patient-home .name {
		font-size: 18px;
	}
}

